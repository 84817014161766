@import "components/ui/theme/index";

.MuiTabs-root.ui-desktop-main-menu {
  border-radius: 0 10px 0 0 !important;

  @media (max-width: $lg-max) {
    display: none;
  }

  .MuiTab-root {
    -webkit-user-drag: none;
    -moz-user-drag: none;
  }
}