@import "components/ui/theme/index";

.ui-pagination-input {
  display: inline-flex;

  .MuiTextField-root {
    min-width: 40px;
    max-width: calc(5ch + #{$spacing-2} + 2px); // Dynamic input width
    height: 40px;
  }

  &:not(:last-child) {
    margin-right: $spacing-1;
  }

  .MuiOutlinedInput-notchedOutline {
    border-radius: 4px;
  }

  .MuiInputBase-input {
    padding: $spacing-1 + 1 $spacing-1 $spacing-1;
  }
}
