/// IE only hack :-(
///
/// @example
///
///   .disabled {
///     filter: grayscale(1);
///
///     // IE10+ doesn't support any reasonable grayscale filter
///     @include ie-only {
///       opacity: 0.5;
///     }
///   }
///
@mixin ie-only {
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    @content;
  }
}

/// Make element disabled
///
/// @example
///
///    .button {
///      ...
///
///      &.disabled {
///         @include disabled;
///      }
///    }
///
@mixin disabled {
  filter: grayscale(1);
  opacity: 0.5;
  pointer-events: none;
}

/// Increase specificity
///
/// @example
///
///    .button {
///      @include increase-specificity {
///        ...
///      }
///    }
///
@mixin increase-specificity {
  &:not(#\9) {
    @content;
  }
}