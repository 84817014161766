@import "components/ui/theme/index";

.ui-home-last-news {
  &__content {
    min-height: 200px;
  }

  &__date {
    float: left;
    position: relative;
    top: 1px;
    margin-right: $spacing-1;
    font-size: $small-font-size;
    font-weight: bold;
  }

  &__content::after {
    content: '';
    clear: both;
  }

  .ui-news-list-item + .ui-news-list-item {
    margin-top: $spacing-2 !important;
  }

  .ui-news-list-item__content {
    margin-bottom: -$spacing-1;
  }
}