@import "components/ui/theme/index";

.ui-news-detail-view {
  content: '';
  clear: both;

  &__date {
    float: left;
    position: relative;
    top: 1px;
    margin-right: $spacing-1;
    font-size: $small-font-size;
    font-weight: bold;
  }
}