@import "components/ui/theme/index";

$ui-desktop-sub-menu-bg: primary-dark !default;
$ui-desktop-sub-menu-fg: get-contrast-color($ui-desktop-sub-menu-bg) !default;

$ui-desktop-sub-menu-items-bg: primary-dark !default;
$ui-desktop-sub-menu-items-fg: get-contrast-color($ui-desktop-sub-menu-items-bg) !default;
$ui-desktop-sub-menu-items-indicator-bg: secondary !default;

.ui-desktop-sub-menu {
  @include theme-color(color, $ui-desktop-sub-menu-fg);
  @include theme-color(background, $ui-desktop-sub-menu-bg);

  @media (max-width: $lg-max) {
    border-radius: 0 10px 0 0;
  }
  
  &__content.MuiContainer-root {
    display: flex;
    
    @media (min-width: $xl-min) {
      padding-right: 0;
    }

    @media (max-width: $xs-max) {
      justify-content: center;
    }
  }

  &__info {
    display: flex;
    margin: $spacing-1 * 1.75 $spacing-3 $spacing-1 * 1.75 0;
  }

  &__icon {
    align-self: center;
    margin-right: $spacing-1 * 1.5;
    width: 32px;
    min-width: 32px;
    height: 32px;

    @media (max-width: $xs-max) {
      display: none;
    }

    .MuiSvgIcon-root {
      width: 100%;
      height: 100%;
    }
  }

  &__heading {
    display: flex;
    align-items: center;
  }

  &__items.MuiTabs-root {
    margin-left: auto;
    border-radius: 0;
    @include theme-color(color, $ui-desktop-sub-menu-items-fg);
    @include theme-color(background, $ui-desktop-sub-menu-items-bg);

    @media (max-width: $lg-max) {
      display: none;
    }
   
    .MuiTabs-indicator {
      @include theme-color(background, $ui-desktop-sub-menu-items-indicator-bg);
    }

    .MuiTab-root {
      -webkit-user-drag: none;
      -moz-user-drag: none;
    }
  }
}