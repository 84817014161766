@import "./cache";
@import "./color";
@import "./list";
@import "config/theme/themes";

/// Get processed theme color
///
/// @param {String} $theme-name - Theme to get color from
/// @param {String} $theme-color-name - Name of color to get
/// @param {List[]} [$color-functions] - Color functions to call on retrieved color
/// @returns {Color} Retrieved color
///
/// @example
///
///   // Get primary color from "light" theme darkened by 10%
///
///   get-theme-color(light, primary, (darken, 10%))
///
@function get-theme-color($theme-name, $theme-color, $color-functions...) {

  //--- Check cache

  $cache-key: $theme-name + $theme-color + $color-functions;
  @if cache-has(colors, $cache-key) {
    @return cache-get(colors, $cache-key);
  }

  //--- Check color modifiers

  $theme-color-name: $theme-color;
  $is-contrast-color: __color-has-modifier($theme-color, contrast);
  @if $is-contrast-color {
    $theme-color-name: __color-remove-modifier($theme-color, contrast);
  }

  //--- Get color

  $color: null;
  @if str-index(inspect($theme-color), "transparent") == 1 {
    $color: transparent;
  }
  @else {
    $color: __get-theme-color($theme-name, $theme-color-name);
    @if $color == null {
      @error "Color #{$theme-color-name} doesn't exist in #{$theme-name} theme";
    }
    @else {

      //--- Get contrast color

      @if $is-contrast-color {
        $color: __get-theme-contrast-color($theme-name, $color);
      }
    
      //--- Apply color functions

      @each $color-function in $color-functions {
        @if length($color-function) > 0 {
          @if type-of(nth($color-function, 1)) == list {
            $color-function: nth($color-function, 1);
          }
          $function-name: nth($color-function, 1);
          $function-args: set-nth($color-function, 1, $color);
          $color: call(get-function($function-name), $function-args...);
        }
      }
    }
  }

  $run: cache-add(colors, $cache-key, $color);
  @return $color;
}

/// Get processed sub-theme color
///
/// @param {String} $theme-name - Theme to get color from
/// @param {String} $sub-theme-name - Sub-theme name
/// @param {String} $theme-color-name - Name of color to get
/// @param {List[]} [$color-functions] - Color functions to call on retrieved color
/// @returns {Color} Retrieved color
///
/// @example
///
///   // Get primary color from "light" theme and "invoices" sub-theme
///
///   get-sub-theme-color(light, invoices, primary)
///
@function get-sub-theme-color($theme-name, $sub-theme-name, $theme-color, $color-functions...) {
  @if str-index(inspect($theme-color), "transparent") == 1 {
    @return transparent;
  }
  $color-name: unquote($sub-theme-name + "-" + $theme-color);
  @return get-theme-color($theme-name, $color-name, $color-functions...);
}

/// Mark color as a base for contrast color
///
/// @example
///
///   $ui-quote-background: paper;
///   $ui-quote-color: get-contrast-color(paper);
///
///   .ui-quote {
///     @include theme-color(background, $ui-quote-background);
///     @include theme-color(color, $ui-quote-color);
///   }
///
/// @param {String} [$theme-color-name] - Theme color name
/// @returns {String} Color marked as a contrast color base
///
@function get-contrast-color($theme-color-name: paper) {
  @return __color-add-modifier(contrast, $theme-color-name);
}

/// Get theme color value by its name (internal)
///
/// @param {String} $theme-name - Theme to get color from
/// @param {String} $theme-color-name - Color name to get
/// @returns {Color} Retrieved color
///
@function __get-theme-color($theme-name, $theme-color-name) {
  $theme-colors: map-get($themes, $theme-name);
  @return map-get($theme-colors, $theme-color-name);
}

/// Compute contrast theme color (internal)
///
/// @param {String} $theme-name - Theme to get contrast color from
/// @param {Color} $color-value - Color to use
/// @returns {Color} Contrast color
///
@function __get-theme-contrast-color($theme-name, $color-value) {
  $text-dark-name: text;
  $text-light-name: text-inverted;
  $text-color: get-theme-color($theme-name, text);
  @if alpha($color-value) < 1 {
    $paper-color: get-theme-color($theme-name, paper);
    $color-value: mixColorOverlay($paper-color, $color-value);
  }
  @if lightness($text-color) > 50 {
    $text-dark-name: text-inverted;
    $text-light-name: text;
  }
  @if lightness($color-value) > 60 {
    @return get-theme-color($theme-name, $text-dark-name);
  }
  @else {
    @return get-theme-color($theme-name, $text-light-name);
  }
}
