@import "components/ui/theme/index";

.MuiFormControlLabel-root.ui-form-control-label {
  .MuiFormControlLabel-label {
    user-select: none;
  }

  &--size-small .MuiTypography-root {
    font-size: $small-font-size;
  }
}
