@import "components/ui/theme/index";

$ui-alert-info-bg: info !default;
$ui-alert-info-fg: get-contrast-color($ui-alert-info-bg) !default;

$ui-alert-success-bg: success !default;
$ui-alert-success-fg: get-contrast-color($ui-alert-success-bg) !default;

$ui-alert-warning-bg: warning !default;
$ui-alert-warning-fg: get-contrast-color($ui-alert-warning-bg) !default;

$ui-alert-error-bg: error !default;
$ui-alert-error-fg: get-contrast-color($ui-alert-error-bg) !default;

.ui-alert {
  .MuiAlert-icon,
  .MuiAlert-action {
    .MuiSvgIcon-root {
      width: 24px;
      height: 24px;
    }
  }

  //--- Variants

  &--info .MuiAlert-root {
    @include theme-color(color, $ui-alert-info-fg);
    @include theme-color(background, $ui-alert-info-bg);

    .MuiSvgIcon-root {
      @include theme-color(fill, $ui-alert-info-fg);
    }
  }

  &--success .MuiAlert-root {
    @include theme-color(color, $ui-alert-success-fg);
    @include theme-color(background, $ui-alert-success-bg);

    .MuiSvgIcon-root {
      @include theme-color(fill, $ui-alert-success-fg);
    }
  }

  &--warning .MuiAlert-root {
    @include theme-color(color, $ui-alert-warning-fg);
    @include theme-color(background, $ui-alert-warning-bg);

    .MuiSvgIcon-root {
      @include theme-color(fill, $ui-alert-warning-fg);
    }
  }

  &--error .MuiAlert-root {
    @include theme-color(color, $ui-alert-error-fg);
    @include theme-color(background, $ui-alert-error-bg);

    .MuiSvgIcon-root {
      @include theme-color(fill, $ui-alert-error-fg);
    }
  }
}
