@import "components/ui/theme/index";

.ui-partner-select-form {
  &__partner {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    font-weight: 600;
  }

  &__place {
    overflow: hidden;
    opacity: 0.8;
    font-style: italic;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}