@import "components/ui/theme/index";

$ui-pagination-button-bg: primary !default;
$ui-pagination-button-hover-bg: $ui-pagination-button-bg, (darken 10%) !default;
$ui-pagination-button-fg: get-contrast-color($ui-pagination-button-bg) !default;
$ui-pagination-button-disabled-bg: $ui-pagination-button-bg (set-alpha, 0.65) !default;

.MuiButton-root.ui-pagination-button {
  margin-bottom: $spacing-1;
  padding: 6px;
  min-width: 40px;
  height: 40px;
  font-weight: 400;
  @include theme-color(color, $ui-pagination-button-fg);
  @include theme-color(background, $ui-pagination-button-bg);

  &:hover {
    @include theme-color(background, $ui-pagination-button-hover-bg);
  }

  &:not(:last-child) {
    margin-right: $spacing-1;
  }

  &--disabled {
    pointer-events: none;
    @include theme-color(background, $ui-pagination-button-disabled-bg);
  }
}
