@import "components/ui/theme/index";

$ui-desktop-page-info-level-1-bg: primary-dark !default;
$ui-desktop-page-info-level-1-fg: get-contrast-color($ui-desktop-page-info-level-1-bg) !default;

$ui-desktop-page-info-level-2-bg: secondary !default;
$ui-desktop-page-info-level-2-fg: get-contrast-color($ui-desktop-page-info-level-2-bg) !default;

.ui-desktop-page-info {
  &__content {
    padding-top: 20px;
    padding-bottom: 20px;
    font-weight: 600;
    transition-property: color, background;
    transition-duration: $speed-fast;
    @include theme-color(color, $ui-desktop-page-info-level-1-fg);
    @include theme-color(background, $ui-desktop-page-info-level-1-bg);

    @media (max-width: $xs-max) {
      text-align: center;
    }
  }

  @at-root .ui-desktop-sub-menu + & &__content {
    @include theme-color(color, $ui-desktop-page-info-level-2-fg);
    @include theme-color(background, $ui-desktop-page-info-level-2-bg);
  }
  
  &__message {
    opacity: 0.8;
  }
}