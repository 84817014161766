@import "components/ui/theme/index";

$ui-tabs-bg: primary !default;
$ui-tabs-fg: get-contrast-color($ui-tabs-bg) !default;
$ui-tabs-indicator-bg: primary-dark !default;

.MuiTabs-root.ui-tabs {
  border-radius: 4px 4px 0 0;
  @include theme-color(color, $ui-tabs-fg);
  @include theme-color(background, $ui-tabs-bg);
 
  .MuiTabs-indicator {
    height: 100%;
    @include theme-color(background, $ui-tabs-indicator-bg);
  }

  .MuiTabScrollButton-root {
    transition-property: width, opacity;
    transition-duration: $speed-fast;

    &:hover {
      opacity: 1;
    }

    &.Mui-disabled {
      width: 0;
    }
  }
}