@import "components/ui/theme/index";

.MuiCardActions-root.ui-card-actions {
  margin-top: $spacing-2;
  padding: 0 $spacing-2 $spacing-2;

  @at-root .MuiCardContent-root + & {
    padding-top: 0;
  }

  .MuiButton-root {
    max-width: 200px;
  }
}