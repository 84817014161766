@import "components/ui/theme/index";

.ui-granted-partners-list {
  overflow-y: auto;
  max-height: 356px;

  &__list {
    width: calc(100% + #{$spacing-1}) !important;
    margin-left: -$spacing-1;
  }
  
  &__item {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;    
  }
}