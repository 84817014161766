@import "components/ui/theme/index";

$ui-view-bg: paper !default;

.MuiContainer-root.ui-view {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  position: relative;
  padding-top: $spacing-4;
  padding-bottom: $spacing-4;
  @include theme-color(background, $ui-view-bg);
}
