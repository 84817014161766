@import "components/ui/theme/index";

$ui-filter-output-fg: text (set-alpha, 0.5) !default;

.ui-filter-output {
  flex: 1;
  position: relative;
  min-height: 150px;

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;  
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__content {
    display: flex;
    align-items: center;
    @include theme-color(color, $ui-filter-output-fg);
  }

  &--narrow &__content {
    flex-direction: column;
  }

  @keyframes ui-filter-output-arrow {
    0% {
      transform: translateY(-2px);
    }

    50% {
      transform: translateY(2px);
    }

    100% {
      transform: translateY(-2px);
    }
  }
  
  &__icon.MuiSvgIcon-root {
    margin-right: $spacing-3;
    width: 48px;
    height: 48px;
    animation: 800ms infinite ui-filter-output-arrow forwards;
  }

  &--narrow &__icon.MuiSvgIcon-root {
    margin: 0 0 $spacing-2;
  }

  &__message.MuiTypography-root {
    font-weight: 600;
    text-transform: uppercase;
  }
}