@import "components/ui/theme/index";

.MuiTab-root.ui-tab {
  z-index: 1;
  padding: $spacing-1 * 1.75 $spacing-3;
  min-width: 128px;
  min-height: 60px;
  text-transform: initial;
  font-weight: 600;
  opacity: 0.8;
  transition: opacity $speed-fast;

  &:hover {
    opacity: 1;
  }

  .MuiTab-wrapper {
    flex-direction: row;

    .MuiSvgIcon-root {
      margin-bottom: 0;
      width: 32px;
      height: 32px;
    }
  }
  
  &.MuiTab-labelIcon .MuiSvgIcon-root {
    margin-right: $spacing-1 * 1.25;
  }
}