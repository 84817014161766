@import "components/ui/theme/index";

$ui-tooltip-kit-box-bg: text (set-alpha, 0.03) !default;

.ui-tooltip-kit {
  &__boxes {
    @media (min-width: $md-min) {
      display: flex;
    }
  }

  &__directions {
    display: flex;
  }

  &__group {
    @media (min-width: $md-min) {
      display: flex;
    }
  }

  .ui-tooltip-box {
    margin-right: $spacing-3;
    border-radius: 4px;
    @include theme-color(background, $ui-tooltip-kit-box-bg);

    @media (max-width: $sm-max) {
      margin-bottom: $spacing-3;
    }
  }

  &__html {
    @media (max-width: $sm-max) {
      width: 212px !important;
    }
  }
}
