@import "components/ui/theme/index";

$ui-form-label-fg: text !default;
$ui-form-label-required-fg: primary !default;

.ui-form-label {
  &.MuiFormLabel-root {
    display: block;
    margin-bottom: $spacing-2;
    font-size: $h3-font-size;
    line-height: $h3-line-height;
    font-weight: $h3-font-weight;
    @include theme-color(color, $ui-form-label-fg);
  }

  svg:first-child {
    margin: -4px $spacing-1 -4px 0;
    width: 24px;
    height: 24px;
  }

  &--required::after {
    content: '*';
    margin-left: 4px;
    @include theme-color(color, $ui-form-label-required-fg);
  }

  &__hint {
    margin: 0 0 -3px $spacing-1;
    width: 19px;
    height: 19px;
    opacity: 0.7;
    transition: opacity $speed-slow;

    &:hover {
      opacity: 0.95;
    }
  }

  &--disabled {
    @include disabled;
  }
}
