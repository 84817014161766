@import "components/ui/theme/index";

$ui-no-data-color: text (set-alpha, 0.5) !default;
$ui-no-data-color-inverted: text-inverted (set-alpha, 0.5) !default;

.ui-no-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  padding: $spacing-3;
  min-height: 100%;
  font-size: $h3-font-size;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  @include theme-color(color, $ui-no-data-color);

  &--inverted {
    @include theme-color(color, $ui-no-data-color-inverted);
  }

  &__content {
    display: flex;
    align-items: center;
  }

  &--narrow &__content {
    flex-direction: column;
  }

  &__icon.MuiSvgIcon-root {
    margin-right: $spacing-3;
    width: 48px;
    height: 48px;
  }

  &--narrow &__icon.MuiSvgIcon-root {
    margin: 0 0 $spacing-2;
  }

  &__message.MuiTypography-root {
    font-weight: 600;
  }
}
