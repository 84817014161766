@import "components/ui/theme/index";

$ui-menu-fg: primary !default;
$ui-menu-border: primary !default;
$ui-menu-margin: 5px;

.ui-menu {
  .MuiMenu-paper {
    border: 1px solid;
    max-width: calc(100% - #{$spacing-2});
    @include theme-color(border-color, $ui-menu-border);
    @include theme-color(color, $ui-menu-fg);
  }

  .MuiList-root {
    max-width: 480px;
  }

  .MuiListItemIcon-root {
    min-width: 24px;
    margin-right: $spacing-2;
    color: inherit;

    .MuiSvgIcon-root {
      width: 24px;
      height: 24px;
    }
  }

  &--margin-top .MuiMenu-paper {
    margin-top: $ui-menu-margin;
  }

  &--margin-left .MuiMenu-paper {
    margin-left: $ui-menu-margin;
  }

  &--margin-right .MuiMenu-paper {
    margin-right: $ui-menu-margin;
  }

  &--margin-bottom .MuiMenu-paper {
    margin-bottom: $ui-menu-margin;
  }
}