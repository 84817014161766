@import "components/ui/theme/index";

.MuiGrid-root.ui-grid {
  &--no-vertical-spacing {
    margin-top: 0;
    margin-bottom: 0;

    > .MuiGrid-item {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &--no-horizontal-spacing {
    margin-left: 0;
    margin-right: 0;

    > .MuiGrid-item {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &--disabled {
    @include disabled;
  }
}
