@import "components/ui/theme/index";

$ui-app-footer-divider: text (set-alpha, 0.11) !default;
$ui-app-footer-bg: paper !default;
$ui-app-footer-link-divider: primary !default;

.ui-app-footer {
  padding-bottom: $spacing-1 * 1.75;
  border-radius: 0 0 0 10px;
  margin-bottom: $spacing-7;
  @include theme-color(background, $ui-app-footer-bg);

  @media (max-width: $xl-max) {
    margin-bottom: $spacing-4;
  }

  .MuiDivider-root {
    margin-bottom: $spacing-1 * 1.75;
    @include theme-color(border-color, $ui-app-footer-divider);

    @media (max-width: $lg-max) {
      margin-bottom: $spacing-1 * 2.25;
    }
  }

  &__content {
    display: flex;

    @media (max-width: $lg-max) {
      flex-direction: column;
    }
  }

  &__copyright-info,
  &__left-column,
  &__right-column {
    @media (max-width: $lg-max) {
      margin: 0 auto;
    }
  }

  &__copyright-info {
    display: flex;
    margin-bottom: $spacing-1;

    @media (min-width: $xl-min) {
      display: none !important;
    }

    @media (max-width: $lg-max) {
      margin-top: $spacing-2;
    }
    
    @media (max-width: $xs-max) {
      flex-direction: column;
      align-items: center;

      .ui-app-footer__copyright {
        margin: 0 0 $spacing-1;
      }
    }
  }

  &__right-column {
    @media (min-width: $xl-min) {
      margin-left: auto;
    }
  }

  &__left-column {
    display: flex;
    
    @media (max-width: $lg-max) {
      margin-bottom: $spacing-1;

      .ui-app-footer {
        &__copyright,
        &__logo {
          display: none;
        }
      }
    }

    @media (max-width: $xs-max) {
      margin-bottom: $spacing-1 * 1.75;
    }
  }

  &__copyright {
    margin-right: $spacing-4;

    @media (max-width: $lg-max) {
      margin-right: $spacing-3;
    }    
  }

  &__external-links,
  &__internal-links {
    text-align: center;

    @media (min-width: $sm-min) {
      li {
        display: inline-block;
        white-space: nowrap;
      }

      li:not(:last-child) {
        margin-right: 4px;
        
        &::after {
          content: ' |';
          @include theme-color(color, $ui-app-footer-link-divider);
        }
      }
    }
  }

  &__logo {
    width: 100px;
    height: 20px;
    white-space: nowrap;
  }

  &__created-by {
    margin: 4px 4px 0 0;
  }

  &__logo-icon {
    transform-origin: left top;
    transform: scale(0.4);
  }

  &__external-links {
    @media (min-width: $xl-min) {
      margin-right: $spacing-6;
    }
  }
}