@import "components/ui/theme/index";

.ui-form {
  &--disabled {
    @include disabled;
  }

  @keyframes ui-form-shake {
    25% {
      transform: translateX(-2px);
    }

    75% {
      transform: translateX(2px);
    }

    100% {
      transform: translateX(0);
    }
  }

  &__invalid {
    animation: ui-form-shake $speed-slow !important;
    animation-iteration-count: 2 !important;
  }
}