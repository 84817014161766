@import "components/ui/theme/index";

.ui-card-kit {
  &__card.MuiCard-root {
    margin: initial;
    max-width: 480px;

    .MuiCardMedia-root {
      height: 160px;
    }

    &--first .MuiCardMedia-root {
      background-position: right center;
    }
  }
}