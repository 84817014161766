@import "components/ui/theme/index";

$upload-zone-border: text (set-alpha, 0.4) !default;
$upload-zone-fg: text !default;

$upload-zone-hover-border: primary !default;
$upload-zone-hover-fg: text !default;

.ui-upload-zone {
  &__zone {
    outline: none;
    border: 1px dashed;
    padding: $spacing-5;
    text-align: center;
    font-style: italic;
    transition-property: border-color, color;
    transition-duration: $speed-slow;
    cursor: pointer;
    @include theme-color(color, $upload-zone-fg);
    @include theme-color(border-color, $upload-zone-border); 
  
    &:hover {
      @include theme-color(color, $upload-zone-hover-fg);
      @include theme-color(border-color, $upload-zone-hover-border); 
    }
  }
  
  &__file-types.MuiTypography-root {
    margin-top: $spacing-1 * 1.5;
    font-style: italic;
    @include theme-color(color, $upload-zone-fg);
  }
}