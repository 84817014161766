@import "components/ui/theme/index";

.ui-toggle-button-kit {
  &__items {
    @media (min-width: $md-min) {
      display: flex;
    }
  }

  &__item {
    @media (min-width: $md-min) {
      &:not(:last-child) {
        margin-right: $spacing-3;
      }
    }

    @media (max-width: $sm-max) {
      margin-top: $spacing-3;
    }
  }
}
