@import "components/ui/theme/index";
@import "components/ui/filter/variables";

.ui-filter-picker-field {
  .MuiOutlinedInput-notchedOutline,
  .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-width: 2px;
    border-top-width: 0;
    border-left-width: 0;
  }

  .MuiInputBase-root {
    transition: box-shadow $speed-fast;
  }

  .MuiOutlinedInput-input {
    padding: $spacing-1 * 1.25 0 $spacing-1 * 1.5 $spacing-1 * 1.5 !important;

    &::selection {
      @include theme-color(color, $ui-filter-field-selection-fg);
      @include theme-color(background, $ui-filter-field-selection-bg);
    }
  }

  .ui-text-field--disabled {
    filter: initial;
  }

  //--- Default colors

  .MuiInputBase-root,
  .MuiInputBase-root.Mui-focused {
    @include theme-color(color, $ui-filter-field-fg);
    @include theme-color(background, $ui-filter-field-bg);

    .MuiInputBase-input::placeholder {
      @include theme-color(color, $ui-filter-field-placeholder);
    }

    .MuiOutlinedInput-notchedOutline {
      @include theme-color(border-color, $ui-filter-field-border);
    }

    .MuiIconButton-root {
      @include theme-color(color, $ui-filter-field-fg);
    }
  }

  //--- Focus colors

  .MuiInputBase-root.Mui-focused {
    box-shadow: 0 0 10px rgba(#000, 0.05) inset;
  }
}