@import "components/ui/theme/index";

$ui-snackbar-info-bg: info !default;
$ui-snackbar-info-fg: get-contrast-color($ui-snackbar-info-bg) !default;

$ui-snackbar-success-bg: success !default;
$ui-snackbar-success-fg: get-contrast-color($ui-snackbar-success-bg) !default;

$ui-snackbar-warning-bg: warning !default;
$ui-snackbar-warning-fg: get-contrast-color($ui-snackbar-warning-bg) !default;

$ui-snackbar-error-bg: error !default;
$ui-snackbar-error-fg: get-contrast-color($ui-snackbar-error-bg) !default;

.ui-snackbar {
  flex-wrap: nowrap !important;
  flex: 1 0 auto !important;

  &__container > div > div {
    justify-content: center;
  }
  
  #notistack-snackbar {
    font-size: 1rem;

    .MuiSvgIcon-root {
      margin-right: $spacing-2;
    }

    + div {
      margin-left: auto;
      padding-left: $spacing-1 * 1.25;
    }
  }

  .MuiSvgIcon-root {
    width: 24px;
    height: 24px;
  }

  //--- Variants

  &--info {
    @include theme-color(color, $ui-snackbar-info-fg);
    @include theme-color-important(background, $ui-snackbar-info-bg);

    .MuiSvgIcon-root {
      @include theme-color(fill, $ui-snackbar-info-fg);
    }
  }

  &--success {
    @include theme-color(color, $ui-snackbar-success-fg);
    @include theme-color-important(background, $ui-snackbar-success-bg);

    .MuiSvgIcon-root {
      @include theme-color(fill, $ui-snackbar-success-fg);
    }
  }

  &--warning {
    @include theme-color(color, $ui-snackbar-warning-fg);
    @include theme-color-important(background, $ui-snackbar-warning-bg);

    .MuiSvgIcon-root {
      @include theme-color(fill, $ui-snackbar-warning-fg);
    }
  }

  &--error {
    @include theme-color(color, $ui-snackbar-error-fg);
    @include theme-color-important(background, $ui-snackbar-error-bg);

    .MuiSvgIcon-root {
      @include theme-color(fill, $ui-snackbar-error-fg);
    }
  }
}
