@import "components/ui/theme/index";

$ui-preloader-kit-container-border: text (set-alpha, 0.25) !default;
$ui-preloader-kit-container-bg: text (set-alpha, 0.03) !default;

body .ui-preloader-kit {
  &__container {
    position: relative;
    margin-top: $spacing-3;
    border: 1px solid;
    width: 240px;
    height: 240px;
    @include theme-color(border-color, $ui-preloader-kit-container-border);
    @include theme-color(background, $ui-preloader-kit-container-bg);
  }
}
