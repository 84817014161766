@import "components/ui/theme/index";

$ui-checkbox-kit-custom-color: error !default;

.ui-checkbox-kit {
  &__custom.ui-checkbox.Mui-checked .MuiSvgIcon-root {
    @include theme-color(color, $ui-checkbox-kit-custom-color);
  }

  .MuiFormGroup-root {
    display: inline-flex;
  }
}
