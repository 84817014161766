@import "components/ui/theme/index";

$ui-banner-bg: text !default;
$ui-banner-content-bg: text (set-lightness, 75%) !default;
$ui-banner-content-overlay: text (set-alpha, 0.44) !default;
$ui-banner-content-fg: text-inverted !default;

body .ui-banner {
  .ui-banner__container,
  .ui-banner__image {
    border-radius: 0 16px 0 16px;
  }

  .ui-banner__container.ui-link {
    display: block;
    overflow: hidden;
    position: relative;
    margin: 0 auto;
    max-width: 720px;
    cursor: pointer;
    @include theme-color(background, $ui-banner-bg);

    &:hover {
      text-decoration: none;
    }
  }

  .ui-sm-min .ui-banner__container {
    height: 160px;
  }

  //--- Image

  .ui-banner__image {
    background-size: cover;
    background-position: center right;
    transition: transform $speed-slow;
  }

  .ui-sm-min .ui-banner__image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .ui-xs-max .ui-banner__image {
    height: 160px;
  }

  .ui-banner__container:hover .ui-banner__image {
    transform: scale(1.1);
  }

  //--- Content box

  .ui-banner__content,
  .ui-banner__content-corner {
    position: relative;
    @include theme-color(background, $ui-banner-content-bg);

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      @include theme-color(background, $ui-banner-content-overlay);
    }
  }

  .ui-sm-min .ui-banner__content {
    position: absolute;
    top: 0;
    left: 0;
    bottom: -24px;
    width: 33%;
    transform: translateZ(1px);
  }

  .ui-xs-max .ui-banner__content {
    padding: $spacing-2 $spacing-3;
  }

  .ui-banner__content-corner {
    content: '';
    position: absolute;
    top: -64px;
    left: calc(100% - 119px);
    width: 85px;
    bottom: -24px;
    transform-origin: top right;
    transform: rotate(-28deg) translateZ(1px);
    box-shadow: 4px 0 2px rgba(0, 0, 0, 0.16);
  }

  .ui-xs-max .ui-banner__content-corner {
    display: none;
  }

  //--- Contents

  .ui-banner__heading,
  .ui-banner__more {
    position: relative;
    @include theme-color(color, $ui-banner-content-fg);
  }

  .ui-banner__heading {
    font-weight: normal;
    line-height: 1.12em;
  }

  .ui-sm-min .ui-banner__heading {
    overflow: hidden;
    position: absolute;
    top: $spacing-3;
    left: $spacing-4;
    right: 0;
    height: 88px;
  }

  .ui-xs-max .ui-banner__heading {
    line-height: 1.3em;
  }

  .ui-banner__more {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0 100%;
    background-repeat: no-repeat;
    background-size: 0 2px;
    transition: background-size $speed-fast;
  }

  .ui-sm-min .ui-banner__more {
    position: absolute;
    left: $spacing-4;
    bottom: $spacing-6;
    max-width: calc(100% - 30px);
  }

  .ui-xs-max .ui-banner__more {
    display: inline-block;
    margin-top: $spacing-2;
  }

  .ui-banner__container:hover .ui-banner__more {
    background-size: 100% 2px;
  }
}
