
//--- Typography

$body-font-family: "Open Sans", sans-serif;
$body-line-height: 1.4em;
$body-font-size: 16px;
$small-font-size: 14px;
$h1-font-size: 29px;
$h1-font-weight: 600;
$h1-line-height: 1.2em;
$h2-font-size: 25px;
$h2-font-weight: 600;
$h2-line-height: 1.2em;
$h3-font-size: 17px;
$h3-font-weight: 700;
$h3-line-height: 1.2em;

//--- Breakpoints

$xs-min: 0px;
$sm-min: 480px;
$md-min: 768px;
$lg-min: 992px;
$xl-min: 1200px;
$xxl-min: 1440px;
$xs-max: $sm-min - 1;
$sm-max: $md-min - 1;
$md-max: $lg-min - 1;
$lg-max: $xl-min - 1;
$xl-max: $xxl-min - 1;

//--- Spacing

$spacing-0: 0;
$spacing-1: 8px;
$spacing-2: 16px;
$spacing-3: 24px;
$spacing-4: 32px;
$spacing-5: 40px;
$spacing-6: 48px;
$spacing-7: 56px;
$spacing-8: 64px;
$spacing-9: 72px;
$spacing-10: 80px;

//--- Animation speed

$speed-fast: 250ms;
$speed-slow: 350ms;
