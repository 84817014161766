@import "components/ui/theme/index";

$ui-invoice-summary-paid: success !default;
$ui-invoice-summary-unpaid: error !default;

.ui-invoice-summary {
  overflow: hidden;
  position: relative;
  min-height: 70px;

  @media (max-width: $sm-max) {
    max-width: 320px;
    margin: auto;
  }

  &__container {
    display: flex;

    @media (max-width: $sm-max) {
      flex-direction: column;
      justify-content: center;
      text-align: center;
    }
  }
  
  &__icon {
    margin-right: $spacing-2;

    @media (max-width: $sm-max) {
      margin: 0 0 $spacing-2;
    }

    svg {
      vertical-align: top;
      width: 70px;
      height: 70px;
    }
  }

  &--paid &__icon {
    @include theme-color(color, $ui-invoice-summary-paid);
  }

  &--unpaid &__icon {
    @include theme-color(color, $ui-invoice-summary-unpaid);
  }

  &__message {
    display: flex;
    align-items: center;

    @media (max-width: $sm-max) {
      display: block;
    }

    .MuiTypography-root {
      font-weight: normal;
    }
  }
}