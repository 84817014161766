@import "components/ui/theme/index";

$ui-preloader-color: primary !default;
$ui-preloader-color-fullscreen: get-contrast-color(text) !default;

$ui-preloader-size-tiny: 16px !default;
$ui-preloader-size-small: 24px !default;
$ui-preloader-size-normal: 48px !default;
$ui-preloader-size-big: 96px !default;

$ui-preloader-contrast-primary: get-contrast-color(primary) !default;
$ui-preloader-contrast-secondary: get-contrast-color(secondary) !default;
$ui-preloader-contrast-text: get-contrast-color(text) !default;

.ui-preloader {

  //--- Display

  &--display-container {
    position: absolute !important;
    z-index: 100 !important;
    cursor: progress;
  }

  &--display-fullscreen {
    z-index: 100000 !important;
    cursor: wait;
  }

  &--display-inline {
    display: inline-block !important;
    vertical-align: top !important;
    position: static !important;
    padding: 0 !important;
    pointer-events: none;

    .MuiCollapse-wrapperInner {
      display: flex;
    }

    .MuiCircularProgress-root {
      margin-left: 12px
    }
  }

  //--- Color

  .MuiCircularProgress-root {
    @include theme-color(color, $ui-preloader-color);
  }

  &--display-fullscreen .MuiCircularProgress-root {
    @include theme-color(color, $ui-preloader-color-fullscreen);
  }

  &--contrast-primary .MuiCircularProgress-root {
    @include theme-color(color, $ui-preloader-contrast-primary);
  }

  &--contrast-secondary .MuiCircularProgress-root {
    @include theme-color(color, $ui-preloader-contrast-secondary);
  }

  &--contrast-text .MuiCircularProgress-root {
    @include theme-color(color, $ui-preloader-contrast-text);
  }

  //--- Size

  &--display-fullscreen#{&}--size-default .MuiCircularProgress-root {
    width: $ui-preloader-size-big !important;
    height: $ui-preloader-size-big !important;
  }

  &--display-container#{&}--size-default .MuiCircularProgress-root {
    width: $ui-preloader-size-normal !important;
    height: $ui-preloader-size-normal !important;
  }

  &--display-inline#{&}--size-default .MuiCircularProgress-root {
    width: $ui-preloader-size-small !important;
    height: $ui-preloader-size-small !important;
  }

  &--size-tiny .MuiCircularProgress-root {
    width: $ui-preloader-size-tiny !important;
    height: $ui-preloader-size-tiny !important;
  }

  &--size-small .MuiCircularProgress-root {
    width: $ui-preloader-size-small !important;
    height: $ui-preloader-size-small !important;
  }

  &--size-normal .MuiCircularProgress-root {
    width: $ui-preloader-size-normal !important;
    height: $ui-preloader-size-normal !important;
  }

  &--size-big .MuiCircularProgress-root {
    width: $ui-preloader-size-big !important;
    height: $ui-preloader-size-big !important;
  }
}
