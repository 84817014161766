@import "components/ui/theme/index";

$ui-user-menu-info-fg: disabled !default;
$ui-user-menu-info-bg: paper !default;

.ui-user-menu {
  &__icon.MuiSvgIcon-root {
    width: 48px;
    height: 48px;
  }

  //--- User info

  &__info {
    &.MuiMenuItem-root {
      cursor: default;

      &,
      &:hover {
        @include theme-color(color, $ui-user-menu-info-fg);
        @include theme-color(background, $ui-user-menu-info-bg);
      } 

      .MuiListItemText-primary {
        white-space: normal;
      }

      .MuiTouchRipple-root {
        display: none;
      }
    }

    &.MuiMenuItem-root,
    &.MuiDivider-root {
      @media (min-width: $md-min) {
        display: none;
      }
    }
  }
}