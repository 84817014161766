@import "config/theme/themes";
@import "../functions/theme";

/// Add color rule for each theme
///
/// @param {String} $css-rule - CSS rule
/// @param {String} $theme-color-name - Theme color name
/// @param {List[]} [$color-functions] - Color functions to call on retrieved color
///
/// @example
///
///   // Basic usage
///
///   .test {
///     @include theme-color(background, primary);
///   }
///
/// @output
///
///   #ui-theme--light .test {
///     background: cyan;
///   }
///
///   #ui-theme--dark .test {
///     background: darkblue;
///   }
///
/// @example
///
///   // Apply color functions (darken, lighten, set-hue, set-saturation, set-lightness, set-alpha, ...)
///
///   $ui-preloader-color: primary;
///   $ui-preloader-overlay: paper, (darken, 10%), (set-alpha, 0.95);
///
///   .ui-preloader {
///     @include theme-color(background, $ui-preloader-overlay);
///
///     svg {
///       @include theme-color(fill, $ui-preloader-color);
///     }
///   }
///
/// @example
///
///   // Compute contrast color
///
///   $ui-quote-background: paper;
///   $ui-quote-color: get-contrast-color(paper);
///
///   .ui-quote {
///     @include theme-color(background, $ui-quote-background);
///     @include theme-color(color, $ui-quote-color);
///   }
///
/// @example
///
///   // Use string template
///
///   $ui-container-border: text !default;
///
///   .ui-container {
///     @include theme-color(border "1px solid {color}", $ui-container-border);
///   }
///
@mixin theme-color($css-rule, $args) {

  //--- Template

  $template: null;
  @if type_of($css-rule) == "list" {
    $template: nth($css-rule, 2);
    $css-rule: nth($css-rule, 1);
  }
  @each $theme-name, $theme-colors in $themes {

    //--- Theme

    @at-root #ui-theme--#{$theme-name} & {
      $color: get-theme-color($theme-name, $args...);
      @include __output-color($css-rule, $color, $template);
    }

    //--- Sub-theme

    @if variable-exists(sub-themes) {
      @each $sub-theme-name in $sub-themes {
        @at-root #ui-theme--#{$theme-name} .ui-sub-theme--#{$sub-theme-name} & {
          $color: get-sub-theme-color($theme-name, $sub-theme-name, $args...);
          @include __output-color($css-rule, $color, $template);
        }
      }
    }
  }
}

/// Add color rule for each theme including the !important directive
///
/// @param {String} $css-rule - CSS rule
/// @param {String} $color - Color to set
///
/// @example
///
///   // Basic usage
///
///   .test {
///     @include theme-color-important(background, primary);
///   }
///
/// @output
///
///   #ui-theme--light .test {
///     background: cyan !important;
///   }
///
///   #ui-theme--dark .test {
///     background: darkblue !important;
///   }
///
@mixin theme-color-important($css-rule, $color) {
   @include theme-color($css-rule "{color} !important", $color);
}

/// Output theme color (internal)
///
/// @param {String} $css-rule - CSS rule
/// @param {String} $color - Color value
/// @param {String} $template - String in the "1px solid {color}" form
///
/// @example
///
///   .ui-container {
///     @include theme-color(border, red, "1px solid {color}");
///   }
///
///   // Output:
///
///   .ui-container {
///     border: 1px solid red;
///   }
///
@mixin __output-color($css-rule, $color, $template: null) {
  @if $template {
    @if type_of($template) != "string" {
      @error "Invalid argument for parameter $template. Expected string.";
    }
    #{$css-rule}: str-replace(unquote($template), "{color}", $color);
  }
  @else {
    #{$css-rule}: $color;
  }
}
