@import "components/ui/theme/index";

$ui-checkbox-fg: text !default;
$ui-checkbox-checked-fg: secondary !default;

.ui-checkbox {
  &,
  .MuiFormControlLabel-root {
    @include theme-color(color, $ui-checkbox-fg);
  }

  &.Mui-checked .MuiSvgIcon-root {
    @include theme-color(color, $ui-checkbox-checked-fg);
  }

  &:not(.Mui-checked) .MuiSvgIcon-root {
    opacity: 0.85;
  }
  
  &.Mui-disabled {
    @include disabled;
  }

  &.MuiCheckbox-root .MuiSvgIcon-root {
    width: 24px;
    height: 24px;
  }

  &--hover-animation.MuiFormControlLabel-root:not(.Mui-disabled) {
    .MuiTypography-root {
      text-decoration: none;
      background-image: linear-gradient(currentColor, currentColor);
      background-position: 0 100%;
      background-repeat: no-repeat;
      transition: background-size $speed-fast;
      background-size: 0 1px;
    }
  
    &:hover,
    &:focus {
      .MuiTypography-root {
        background-size: 100% 1px;
      }
    }
  }

  &:not(&--hover-animation) {
    &:hover,
    &:focus {
      .MuiTypography-root {
        text-decoration: underline;
      }
    }
  }

  @at-root .ui-table & {
    margin: -14px -12px -10px;
  }
}