@import "components/ui/theme/index";

.ui-autocomplete-controls {
  position: absolute;
  right: $spacing-1;

  &__button {
    display: inline-flex;

    &--clear {
      opacity: 0;
      pointer-events: none;
      transition: opacity $speed-fast;
    }

    @at-root .ui-autocomplete:not(.ui-autocomplete--empty):hover &--clear {
      opacity: 1;
      pointer-events: initial;
    }

    &--toggle {
      transition: transform $speed-slow;

      .MuiSvgIcon-root {
        transform: scale(1.5);
      }
    }

    @at-root .ui-autocomplete--open &--toggle {
      transform: rotate(180deg);
    }
  }
}
