@import "components/ui/theme/index";

.ui-data-list-item {
  &__icon.MuiSvgIcon-root {
    transform: rotate(-90deg);
    transition: transform $speed-fast;
  }

  &--open &__icon {
    transform: rotate(0);
  }
}