@import "components/ui/theme/index";

$ui-file-icon-bg: primary !default;
$ui-file-icon-fg: get-contrast-color($ui-file-icon-bg) !default;
$ui-file-icon-corner: primary-light !default;

.ui-file-icon {
  overflow: hidden;
  position: relative;
  margin: 0 2px;
  width: 26px;
  height: 32px;
  
  &__label {
    overflow: hidden;
    position: absolute;
    z-index: 2;
    left: 3px;
    right: 3px;
    bottom: 3px;
    font-size: 10px;
    line-height: 1.1em;
    font-weight: 600;
    @include theme-color(color, $ui-file-icon-fg);
  }

  //--- Corner

  &__corner {
    position: absolute;
    top: 2px;
    right: 2px;
    transform: rotate(45deg);
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
    @include theme-color(background, $ui-file-icon-corner);

  }

  &::before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 8px;
    bottom: 0;
    @include theme-color(background, $ui-file-icon-bg);
  }

  &::after {
    content: '';
    position: absolute;
    top: 8px;
    right: 0;
    width: 8px;
    bottom: 0;
    @include theme-color(background, $ui-file-icon-bg);
  }
}