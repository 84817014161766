@import "components/ui/theme/index";

$ui-accordion-summary-bg: primary !default;
$ui-accordion-summary-fg: get-contrast-color($ui-accordion-summary-bg) !default;

.MuiAccordionSummary-root.ui-accordion-summary {
  border-radius: inherit;
  font-weight: 600;
  @include theme-color(color, $ui-accordion-summary-fg);  
  @include theme-color(background, $ui-accordion-summary-bg);

  &,
  &.Mui-expanded {
    min-height: 48px;
  }

  .MuiIconButton-root,
  .MuiAccordionSummary-expandIconWrapper {
    color: inherit;
  }

  .MuiAccordionSummary-content,
  .MuiAccordionSummary-content.Mui-expanded {
    margin: $spacing-1 * 1.75 0;
  }

  .MuiAccordionSummary-content {
    transition: opacity $speed-fast;
  }

  .MuiIconButton-root {
    transition-property: opacity, transform;
    transition-duration: $speed-fast;
  }

  &:hover {
    .MuiAccordionSummary-content,
    .MuiIconButton-root {
      opacity: 0.7;
    }
  }
}