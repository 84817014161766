@import "components/ui/theme/index";

$ui-autocomplete-border: primary !default;
$ui-autocomplete-bg: paper !default;

$ui-autocomplete-option-bg: $ui-autocomplete-bg !default;
$ui-autocomplete-option-even-bg: text (set-alpha, 0.0275) !default;
$ui-autocomplete-option-fg: primary !default;

$ui-autocomplete-option-hover-bg: primary !default;
$ui-autocomplete-option-hover-fg: get-contrast-color($ui-autocomplete-option-hover-bg) !default;

$ui-autocomplete-option-selected-bg: primary (set-alpha, 0.1) !default;
$ui-autocomplete-option-selected-fg: primary !default;


$ui-autocomplete-no-options-bg: $ui-autocomplete-bg !default;
$ui-autocomplete-no-options-fg: primary (set-alpha, 0.6) !default;

.ui-autocomplete-popper {

  //--- Container

  &__content {
    opacity: 0;
    backface-visibility: hidden;
    transition-property: opacity, transform;
    transition-duration: $speed-slow;
    transform: scaleY(1.5) rotateX(90deg);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.01); // Edge transform glitch workaround
  }

  &--open &__content {
    opacity: 1;
    transform: scale(1) rotateX(0);
  }

  .MuiAutocomplete-paper {
    border: 1px solid;
    border-radius: 0;
    @include theme-color(border-color, $ui-autocomplete-border);
    @include theme-color(background, $ui-autocomplete-bg);
  }

  //--- Options

  .MuiAutocomplete-listbox {
    padding: 0;
  }

  .MuiAutocomplete-option {
    flex-direction: column;
    justify-content: center !important;
    align-items: flex-start !important;
    @include theme-color(color, $ui-autocomplete-option-fg);
    @include theme-color(background, $ui-autocomplete-option-bg);

    &:nth-child(even) {
      @include theme-color(background, $ui-autocomplete-option-even-bg);
    }

    &[data-focus=true] {
      @include theme-color(color, $ui-autocomplete-option-selected-fg);
      @include theme-color(background, $ui-autocomplete-option-selected-bg);
    }

    &:hover {
      @include theme-color(color, $ui-autocomplete-option-hover-fg);
      @include theme-color(background, $ui-autocomplete-option-hover-bg);
    }

    > div {
      width: 100%;
    }
  }

  .MuiAutocomplete-noOptions {
    @include theme-color(color, $ui-autocomplete-no-options-fg);
    @include theme-color(background, $ui-autocomplete-no-options-bg);
  }
}
