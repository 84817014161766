@import "components/ui/theme/index";

$ui-payment-status-unpaired: error !default;
$ui-payment-status-paired: success !default;

.ui-payment-status {
  display: flex;

  .MuiSvgIcon-root {
    margin-right: $spacing-1 * 1.25;
    width: 24px;
    height: 24px;
  }

  &--unpaired {
    @include theme-color(color, $ui-payment-status-unpaired);
  }

  &--paired {
    @include theme-color(color, $ui-payment-status-paired);
  }
  
  @at-root .ui-table & {
    margin: -1px 0;
  }
}