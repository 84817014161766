@import "components/ui/theme/index";

.MuiFormControlLabel-root.ui-activation-form {
  &__agreement {
    cursor: initial;

    .MuiFormControlLabel-label {
      background: none !important;
    }
  }
}