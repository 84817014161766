@import "components/ui/theme/index";

.ui-badge-kit {
  &__item {
    display: inline-block;
    vertical-align: top;
    margin-right: $spacing-4;

    .MuiSvgIcon-root {
      width: 32px;
      height: 32px;
    }
  }
}
