@import "components/ui/theme/index";

$ui-picker-field-button-fg: primary !default;
$ui-picker-field-button-bg: $ui-picker-field-button-fg (set-alpha, 0.1) !default;

.ui-picker-field .MuiTextField-root {
  .MuiInputBase-adornedEnd {
    padding-right: 0;
  }

  .MuiInputAdornment-positionEnd {
    position: absolute;
    right: 10px;
  }

  .MuiInputBase-input {
    padding-right: 46px;
  }

  .MuiIconButton-root {
    padding: $spacing-1 * 0.75;
    margin: 0;
    @include theme-color(color, $ui-picker-field-button-fg);

    &:hover {
      @include theme-color(background, $ui-picker-field-button-bg);
    }
  }
}
