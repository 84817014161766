@import "components/ui/theme/index";

$ui-link-color: primary !default;
$ui-link-focus-color: secondary !default;

.ui-link {
  transition: color $speed-fast;
  cursor: pointer;
  @include theme-color(color, $ui-link-color);

  &:focus {
    @include theme-color(color, $ui-link-focus-color);
  }

  &--size-small {
    font-size: $small-font-size;
  }

  &--disabled {
    @include disabled;
  }

  &:not(&--hover-animation) {
    &.ui-link--underline {
      text-decoration: underline;
  
      &:hover {
        text-decoration: none;
      }
    }
    
    &:not(.ui-link--underline) {
      text-decoration: none;
      
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &--hover-animation {
    text-decoration: none;
    background-image: linear-gradient(currentColor, currentColor);
    background-repeat: no-repeat;
    transition: background-size $speed-fast;
  }

  &--hover-animation#{&}--underline {
    background-size: 100% 1px;
    background-position: 100% 100%;

    &:hover {
      background-size: 0 1px;  
    }
  }

  &--hover-animation:not(&--underline) {
    background-size: 0 1px;
    background-position: 0 100%;

    &:hover {
      background-size: 100% 1px;
    }
  }
}
