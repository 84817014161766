@import "components/ui/theme/index";

.ui-user-info {
  &__detail {
    max-width: 340px;
    text-align: right;
    
    @media (max-width: $md-max) {
      max-width: 280px;
    }
    @media (max-width: $sm-max) {
      display: none;
    }
  }
  
  &__user,
  &__partner {
    overflow: hidden;
    font-weight: bold;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__partner {
    margin-top: $spacing-1 * 0.5;
  }
}