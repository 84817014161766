@import "components/ui/theme/index";

$ui-ordered-list-bg: primary !default;
$ui-ordered-list-fg: get-contrast-color($ui-ordered-list-bg) !default;

.ui-ordered-list-item {
  display: flex;  
  align-items: center;
  position: relative;
  padding-left: $spacing-8;
  min-height: 40px;
  font-size: $body-font-size;
  counter-increment: ui-ordered-list-counter;

  &:not(:last-child) {
    margin-bottom: $spacing-3;
  }

  &::before {
    content: counter(ui-ordered-list-counter);
    position: absolute;
    top: 0;
    left: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    padding: $spacing-1;
    min-width: 40px;
    height: 40px;
    font-weight: 600;
    @include theme-color(color, $ui-ordered-list-fg);
    @include theme-color(background, $ui-ordered-list-bg);
  }  
}