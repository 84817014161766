@import "components/ui/theme/index";

.ui-description-list {
  dt,
  dd {
    display: inline-block;
    vertical-align: top;
    line-height: 1.5em;
  }

  dt {
    width: calc(45% - #{$spacing-1});
    margin-right: $spacing-1;
  }

  dd {
    width: 55%;
  }

  &--narrow {
    dt,
    dd {
      margin: 0;
      display: block;
      width: auto;
    }

    dd + dt {
      margin-top: $spacing-1;
    }
  }
}