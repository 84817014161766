@import "components/ui/theme/index";

.MuiTypography-root.ui-typography {
  &.MuiTypography-gutterBottom {
    margin-bottom: $spacing-2;
  }
  
  &.MuiTypography-h1.MuiTypography-gutterBottom,
  &.MuiTypography-h2.MuiTypography-gutterBottom {
    margin-bottom: $spacing-3;
  }
  
  &.MuiTypography-h3.MuiTypography-gutterBottom,
  &.MuiTypography-body1.MuiTypography-gutterBottom {
    margin-bottom: $spacing-2;
  }

  &.MuiTypography-h2 > .MuiSvgIcon-root:first-child,
  &.MuiTypography-h2 > div > .MuiSvgIcon-root:first-child {
    display: inline-block;
    vertical-align: middle;
    width: 32px;
    height: 32px;
    margin-top: -4px;
    margin-right: $spacing-1 * 1.25;
  }
}
