@import "components/ui/theme/index";

.ui-partner-select-view {
  justify-content: center;

  &__content {
    margin: 0 auto;
    max-width: 600px;
    width: 100%;
  }
}