@import "components/ui/theme/index";

$ui-data-table-horizontal-menu-bg: paper (set-alpha, 0.35) !default;
$ui-data-table-horizontal-menu-fg: text-inverted !default;
$ui-data-table-horizontal-menu-hover-bg: primary (set-alpha, 0.9) !default;
$ui-data-table-horizontal-menu-hover-fg: text-inverted !default;

$ui-data-table-vertical-menu-bg: primary (set-alpha, 0.75) !default;
$ui-data-table-vertical-menu-fg: text-inverted !default;
$ui-data-table-vertical-menu-hover-bg: primary (set-alpha, 0.9) !default;
$ui-data-table-vertical-menu-hover-fg: text-inverted !default;

.ui-data-table-menu {
  overflow: hidden;
  position: absolute;
  right: -48px;
  top: 50%;
  border-radius: 8px 0 0 8px;
  width: 24px;
  height: 40px;
  cursor: pointer;
  transition-property: color, background;
  transition-duration: $speed-slow;
  transform: translateY(-50%);

  &--pending {
    pointer-events: none;
  }

  &__icon {
    position: absolute;
    top: 7px;
    left: 0px;
    width: 24px;
    height: 24px;
  }
  
  @at-root .ui-data-table--horizontal & {
    @include theme-color(color, $ui-data-table-horizontal-menu-fg);
    @include theme-color(background, $ui-data-table-horizontal-menu-bg);

    &:hover {
      @include theme-color(color, $ui-data-table-horizontal-menu-hover-fg);
      @include theme-color(background, $ui-data-table-horizontal-menu-hover-bg);
    }
  }
  
  @at-root .ui-data-table--vertical & {
    @include theme-color(color, $ui-data-table-vertical-menu-fg);
    @include theme-color(background, $ui-data-table-vertical-menu-bg);

    &:hover {
      @include theme-color(color, $ui-data-table-vertical-menu-hover-fg);
      @include theme-color(background, $ui-data-table-vertical-menu-hover-bg);
    }
  }
}