@import "components/ui/theme/index";

$ui-app-breadcrumbs-bg: paper !default;

.ui-app-breadcrumbs {
  @include theme-color(background, $ui-app-breadcrumbs-bg);

  .MuiBreadcrumbs-root {
    margin: $spacing-1 0;
  }
}