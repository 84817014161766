@import "components/ui/theme/index";

$ui-bg: primary !default;

.Cookies-table {
  thead {
    color: WHITE;
    @include theme-color(background, $ui-bg);
  }
  tr:nth-child(even) {background: #EEE}
  th,td{
    padding-left: 5px;
    padding-right: 5px;
  }
}