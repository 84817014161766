$theme-light: (

  //--- Main palette

  primary: #f59d25,
  primary-light: #f8ba66,
  primary-dark: #e08920,
  secondary: #d17b1f,
  secondary-light: #fba141,
  secondary-dark: #ba6917,
  info: #2674c8,
  success: #94c11e,
  warning: #ff6c0a,
  error: #ef3239,
  text: #575656,
  text-inverted: #fff,
  paper: #fff,
  disabled: #9d9d9c,

  //--- IoT sub-theme palette

  iot-primary: #01b8d0,
  iot-primary-light: #56cede,
  iot-primary-dark: #0093aa,
  iot-secondary: #49bbbc,
  iot-secondary-light: #71cdcd,
  iot-secondary-dark: #008a8c,
  iot-info: #01b8d0,
  iot-success: #94c11e,
  iot-warning: #f59c23,
  iot-error: #f73e24,
  iot-text: #575656,
  iot-text-inverted: #fff,
  iot-paper: #fff,
  iot-disabled: #9d9d9c
);
