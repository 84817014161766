@import "components/ui/theme/index";

$ui-card-bg: text (set-alpha, 0.08) !default;

.MuiCard-root.ui-card {
  position: relative;
  border-radius: 0 10px 0 10px;
  min-height: 100%;
  @include theme-color(background, $ui-card-bg);

  @media (max-width: $lg-max) {
    margin: 0 auto;
    max-width: 540px;
  }

  &--full-height {
    display: flex;
    flex-direction: column;
    
    .MuiCardContent-root {
      flex: 1 1 auto;
    }

    .MuiCardActions-root {
      margin-top: $spacing-1;
    }
  }
}