@import "components/ui/theme/index";

$ui-card-action-area-hover-bg: text-inverted !default;

.MuiCardActionArea-root.ui-card-action-area {
  &:last-child {
    padding-bottom: $spacing-2;
  }

  .MuiCardActionArea-focusHighlight {
    @include theme-color(background, $ui-card-action-area-hover-bg);
  }

  &:hover .MuiCardActionArea-focusHighlight {
    opacity: 0.5;
  }

  .MuiCardContent-root {
    margin-bottom: 0;
  }
}