@import "components/ui/theme/index";

.ui-home-portal-features {
  &__about {
    margin-bottom: $spacing-4;
    
    .MuiTypography-root {
      @media (max-width: $lg-max) {    
        text-align: center;
      }
    }
  }
}