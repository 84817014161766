@import "components/ui/theme/index";

.MuiContainer-root.ui-app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

bot-media-widget > div {
  z-index: 0 !important;
}

@media screen and (min-width: 1200px) {
  bot-media-widget > div {
    right: 30% !important;
  }
}
@media screen and (min-width: 2200px) {
  bot-media-widget > div {
    right: 40% !important;
  }
}