@import "components/ui/theme/index";

.ui-demands-filter {
  @media (min-width: $md-min) {
    &__type {
      flex: 1 1 auto !important;
    }
    
    &__type,
    &__actions {
      flex-basis: initial !important;
      width: auto !important;
      width: auto !important;
    }

    &__actions .ui-button {
      margin-top: 28px;
    }
  }
}