@import "components/ui/theme/index";

$ui-invoice-status-paid: success !default;
$ui-invoice-status-partially-paid-before-due: info !default;
$ui-invoice-status-unpaid-before-due: warning !default;
$ui-invoice-status-partially-paid-after-due: error !default;
$ui-invoice-status-unpaid-after-due: error !default;

.ui-invoice-status {
  display: flex;

  .MuiSvgIcon-root {
    margin-right: $spacing-1 * 1.25;
    width: 24px;
    height: 24px;
  }

  &--tooltip {
    display: inline-block;
    vertical-align: top;
    
    .MuiSvgIcon-root {
      margin-right: 0;
    }
  }

  &--paid {
    @include theme-color(color, $ui-invoice-status-paid);
  }

  &--unpaid-before-due {
    @include theme-color(color, $ui-invoice-status-unpaid-before-due);
  }
  
  &--partially-paid-before-due {
    @include theme-color(color, $ui-invoice-status-partially-paid-before-due);
  }

  &--partially-paid-after-due {
    @include theme-color(color, $ui-invoice-status-partially-paid-after-due);
  }
  
  &--unpaid-after-due {
    @include theme-color(color, $ui-invoice-status-unpaid-after-due);
  }

  @at-root .ui-table & {
    margin: -1px 0;
  }
}