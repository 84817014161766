@import "components/ui/theme/index";
@import "components/ui/filter/variables";

.ui-filter-checkbox {
  display: flex;
  align-items: flex-end;
  height: 100%;

  .MuiCheckbox-root {
    @include theme-color(color, $ui-filter-field-fg);
  }
}