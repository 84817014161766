@import "components/ui/theme/index";

$ui-app-logo-fg: error !default;

.ui-app-logo {
  display: inline-block;
  vertical-align: top;
  width: 200px;
  height: 50px;

  &:not(&--animate) {
    transition: opacity $speed-fast;

    &:hover {
      opacity: 0.75;
    }
  }

  &__symbol {
    margin-right: 8px;
    width: 48px;
    height: 50px;
    transition: transform $speed-slow;
    @include theme-color(color, $ui-app-logo-fg);
  }
 
  &--animate:hover &__symbol {
    transform: translate(69px, -15px);
  }

  &__text {
    width: 142px;
    height: 50px;
    transition: transform $speed-slow;
    @include theme-color(color, $ui-app-logo-fg);
  }

  &--animate:hover &__text {
    transform: translate(-32px, 29px) scale(0.8);
  }
}