@import "components/ui/theme/index";

$ui-button-primary-bg: primary !default;
$ui-button-primary-hover-bg: $ui-button-primary-bg, (darken 10%) !default;
$ui-button-primary-fg: get-contrast-color($ui-button-primary-bg) !default;

$ui-button-secondary-bg: secondary !default;
$ui-button-secondary-hover-bg: $ui-button-secondary-bg, (darken 10%) !default;
$ui-button-secondary-fg: get-contrast-color($ui-button-secondary-bg) !default;

$ui-button-success-bg: success !default;
$ui-button-success-hover-bg: $ui-button-success-bg, (darken 10%) !default;
$ui-button-success-fg: get-contrast-color($ui-button-success-bg) !default;

$ui-button-warning-bg: warning !default;
$ui-button-warning-hover-bg: $ui-button-warning-bg, (darken 10%) !default;
$ui-button-warning-fg: get-contrast-color($ui-button-warning-bg) !default;

$ui-button-error-bg: error !default;
$ui-button-error-hover-bg: $ui-button-error-bg, (darken 10%) !default;
$ui-button-error-fg: get-contrast-color($ui-button-error-bg) !default;

.MuiButton-root.ui-button {
  padding: $spacing-1 $spacing-2;
  vertical-align: top;
  font-weight: 600;
  line-height: 1.4em;
  text-transform: none;

  &--margins {
    margin: 0 $spacing-1 * 1.25 $spacing-1 * 1.25 0;

    &:last-child {
      margin-right: 0;
    }
  }

  .MuiSvgIcon-root {
    width: 24px;
    height: 24px;
  }

  .MuiCircularProgress-root {
    color: inherit !important;
  }

  &--pending {
    pointer-events: none;
  }

  .ui-button__label {
    display: inline-flex;
  }
 
  //--- Color variants

  &--color-primary {
    @include theme-color(color, $ui-button-primary-fg);
    @include theme-color(background, $ui-button-primary-bg);

    &:hover {
      @include theme-color(background, $ui-button-primary-hover-bg);
    }
  }

  &--color-secondary {
    @include theme-color(color, $ui-button-secondary-fg);
    @include theme-color(background, $ui-button-secondary-bg);

    &:hover {
      @include theme-color(background, $ui-button-secondary-hover-bg);
    }
  }

  &--color-success {
    @include theme-color(color, $ui-button-success-fg);
    @include theme-color(background, $ui-button-success-bg);

    &:hover {
      @include theme-color(background, $ui-button-success-hover-bg);
    }
  }

  &--color-warning {
    @include theme-color(color, $ui-button-warning-fg);
    @include theme-color(background, $ui-button-warning-bg);

    &:hover {
      @include theme-color(background, $ui-button-warning-hover-bg);
    }
  }

  &--color-error {
    @include theme-color(color, $ui-button-error-fg);
    @include theme-color(background, $ui-button-error-bg);

    &:hover {
      @include theme-color(background, $ui-button-error-hover-bg);
    }
  }

  &.Mui-disabled {
    @include disabled;
  }
}
