@import "components/ui/theme/index";

.MuiCardContent-root.ui-card-content {
  position: relative;
  margin-bottom: $spacing-2;
  padding-bottom: 0 !important;

  &:last-child {
    margin-bottom: $spacing-3;
  }

  @at-root .MuiCardHeader-root + & {
    padding-top: 0;
  }
}