@import "components/ui/theme/index";

$ui-table-cell-head-border: text-inverted (set-alpha, 0.25) !default;
$ui-table-cell-body-hover-fg: primary !default;

.MuiTableCell-root.ui-table-cell {
  border: none;
  padding: $spacing-2 $spacing-4;
  font-size: $body-font-size;
  line-height: $body-line-height;
  color: inherit;

  @at-root th#{&} {
    font-weight: bold;
  }

  @at-root .MuiTableHead-root & + & {
    border-left: 1px solid;
    @include theme-color(border-color, $ui-table-cell-head-border);
  }
  
  @at-root .ui-table--hover .MuiTableBody-root .MuiTableRow-root:hover & {
    @include theme-color(color, $ui-table-cell-body-hover-fg);
  }
}