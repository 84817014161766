@import "components/ui/theme/index";

$ui-sms-subscribers-list-no-items-fg: disabled !default;
$ui-sms-subscribers-list-select-all-fg: primary !default;

.ui-add-partners-list {
  &__no-items {
    margin-top: $spacing-1;
    font-style: italic;
    @include theme-color(color, $ui-sms-subscribers-list-no-items-fg);
  }

  &__select-all {
    @include theme-color(color, $ui-sms-subscribers-list-select-all-fg);
  }

  &__item {
    overflow: hidden;
    
    .MuiFormControlLabel-root {
      width: 100%;
    }
    
    .MuiFormControlLabel-label {
      display: inline-block;    
      overflow: hidden;
      width: calc(100% - 44px);
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}