@import "components/ui/theme/index";

$ui-radio-fg: text !default;
$ui-radio-checked-fg: secondary !default;

.ui-radio {
  &,
  .MuiFormControlLabel-root {
    @include theme-color(color, $ui-radio-fg);
  }

  &.Mui-checked .MuiSvgIcon-root {
    @include theme-color(color, $ui-radio-checked-fg);
  }

  &:not(.Mui-checked) .MuiSvgIcon-root {
    opacity: 0.85;
  }

  &.Mui-disabled {
    @include disabled;
  }

  &.MuiRadio-root .MuiSvgIcon-root {
    width: 24px;
    height: 24px;
  }

  &.MuiFormControlLabel-root:not(.Mui-disabled) {
    .MuiTypography-root {
      text-decoration: none;
      background-image: linear-gradient(currentColor, currentColor);
      background-position: 0 100%;
      background-repeat: no-repeat;
      transition: background-size $speed-fast;
      background-size: 0 1px;
    }
  
    &:hover,
    &:focus {
      .MuiTypography-root {
        background-size: 100% 1px;
      }
    }
  }
}