@import "components/ui/theme/index";

.ui-kit-view .MuiContainer-root {
  padding-top: $spacing-2;
  padding-bottom: $spacing-2;

  > .MuiDivider-root {
    margin: $spacing-4 0;
  }

  > .MuiTypography-h2,
  > * > .MuiTypography-h2 {
    margin-bottom: $spacing-4 !important;
  }

  > .MuiTypography-h3,
  > * > .MuiTypography-h3 {
    margin-bottom: $spacing-3 !important;
  }

  .ui-kit-view__grid {
    .MuiDivider-root {
      margin: $spacing-4 0;
    }

    @media (max-width: $sm-max) {
      display: none;
    }
  }
}
