@import "components/ui/theme/index";

$ui-sidebar-close: text !default;
$ui-sidebar-close-hover: primary !default;

.MuiDrawer-root.ui-sidebar {
  .ui-sidebar__panel {
    padding: $spacing-2 * 1.25;
    min-width: 280px;
    max-width: 480px;
  }

  .ui-sidebar__heading {
    min-height: 65px;

    .MuiTypography-root {
      padding-right: 32px;
      font-size: 20px;
    }

    .MuiDivider-root {
      margin: $spacing-2 * 1.25 0;
    }
  }

  .ui-sidebar__close {
    position: absolute;
    top: 6px;
    right: 6px;
    cursor: pointer;
    @include theme-color(color, $ui-sidebar-close);

    &:hover {
      @include theme-color(color, $ui-sidebar-close-hover);
    }

    .MuiSvgIcon-root {
      width: 24px;
      height: 24px;
    }
  }
}
