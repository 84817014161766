@import "components/ui/theme/index";
@import "components/ui/filter/variables";

.ui-filter-panel {
  overflow: hidden;
  position: relative;
  z-index: 1;
  padding-top: $spacing-3;
  padding-bottom: $spacing-3;
  @include theme-color(color, $ui-filter-fg);
  @include theme-color(background, $ui-filter-bg);
  
  .MuiFormLabel-root {
    margin-bottom: $spacing-1;
    @include theme-color(color, $ui-filter-fg);
  }

  .MuiFormControlLabel-root,
  .MuiCheckbox-root.Mui-checked .MuiSvgIcon-root,
  .MuiRadio-root.Mui-checked .MuiSvgIcon-root {
    @include theme-color(color, $ui-filter-fg);
  }

  &__content {
    transition: opacity $speed-fast;
  }
}