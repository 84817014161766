@import "components/ui/theme/index";

.ui-icon-link {
  display: inline-flex;
  align-items: center;
  text-decoration: none !important;

  .MuiSvgIcon-root {
    width: 24px;
    height: 24px;
    margin-right: $spacing-1;
  }

  &__label {
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0 100%;
    background-repeat: no-repeat;
    transition: background-size $speed-fast;
    background-size: 0 1px;
  }

  &:hover &__label,
  &:focus &__label {
    background-size: 100% 1px;
  }
}