@import "components/ui/theme/index";

$ui-list-item-fg: text !default;
$ui-list-item-hover-bg: text (set-alpha, 0.05) !default;

.MuiListItem-root.ui-list-item {
  padding: $spacing-1;
  @include theme-color(color, $ui-list-item-fg);

  &:hover {
    @include theme-color(background, $ui-list-item-hover-bg);
  }
}