@import "components/ui/theme/index";

$ui-layout-bg: paper !default;
$ui-layout-gradient: text (set-alpha, 0.2) !default;

body {
  overflow-y: scroll;
  background-attachment: fixed;
  @include theme-color(background-image "linear-gradient(transparent, {color})", $ui-layout-gradient);
  @include theme-color(background-color, $ui-layout-bg);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  min-width: 280px;
}