@import "components/ui/theme/index";

$ui-typography-color: get-contrast-color(paper) !default;
$ui-typography-selection-bg: primary-light !default;
$ui-typography-selection-fg: get-contrast-color($ui-typography-selection-bg) !default;

body {
  font-family: $body-font-family;
  font-size: $body-font-size;
  line-height: $body-line-height;
  @include theme-color(color, $ui-typography-color);
}

*::selection {
  @include theme-color(color, $ui-typography-selection-fg);
  @include theme-color(background, $ui-typography-selection-bg);
}

b,
strong {
  font-weight: bold;
}

i,
em {
  font-style: italic;
}
