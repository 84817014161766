@import "components/ui/theme/index";

$ui-slider-color: primary !default;
$ui-slider-thumb-fg: get-contrast-color(primary) !default;

$ui-slider-label-fg: text (set-alpha, 0.7) !default;
$ui-slider-label-active-fg: text !default;

.MuiSlider-root.ui-slider {
  margin-left: $spacing-3;
  margin-right: $spacing-3;
  width: calc(100% - #{$spacing-6});

  .MuiSlider-markLabel {
    @include theme-color(color, $ui-slider-label-fg);
  }

  .MuiSlider-markLabelActive {
    @include theme-color(color, $ui-slider-label-active-fg);
  }
  
  &,
  .MuiSlider-track,
  .MuiSlider-thumb {
    @include theme-color(color, $ui-slider-color);
  }

  .MuiSlider-valueLabel > span {
    @include theme-color(background, $ui-slider-color);

    > span {
      @include theme-color(color, $ui-slider-thumb-fg);
    }
  }
}
