@import "./string";

/// Set color hue
///
/// @param {Color} $color - Color to adjust
/// @param {Number} $hue - Hue degree
/// @returns {Color} Adjusted color
///
/// @example
///
///   $my-color: primary (set-hue, 120);
///
///   .my-element {
///     @include theme-color(background, $my-color);
///   }
///
@function set-hue($color, $hue) {
  $current-value: hue($color);
  $value: $hue - $current-value;
  @return adjust_color($color, $hue: $value);
}

/// Set color saturation
///
/// @param {Color} $color - Color to adjust
/// @param {Number} $saturation - Saturation percentage
/// @returns {Color} Adjusted color
///
/// @example
///
///   $my-color: primary (set-saturation, 50%);
///
///   .my-element {
///     @include theme-color(background, $my-color);
///   }
///
@function set-saturation($color, $saturation) {
  $current-value: saturation($color);
  $value: $saturation - $current-value;
  @return adjust_color($color, $saturation: $value);
}

/// Set color lightness
///
/// @param {Color} $color - Color to adjust
/// @param {Number} $lightness - Lightness percentage
/// @returns {Color} Adjusted color
///
/// @example
///
///   $my-color: primary (set-lightness, 50%);
///
///   .my-element {
///     @include theme-color(background, $my-color);
///   }
///
@function set-lightness($color, $lightness) {
  $current-value: lightness($color);
  $value: $lightness - $current-value;
  @return adjust_color($color, $lightness: $value);
}

/// Set color alpha
///
/// @param {Color} $color - Color to adjust
/// @param {Number} $alpha - Alpha value
/// @returns {Color} Adjusted color
///
/// @example
///
///   $my-color: primary (set-alpha, 0.5);
///
///   .my-element {
///     @include theme-color(background, $my-color);
///   }
///
@function set-alpha($color, $alpha) {
  $current-value: alpha($color);
  $value: $alpha - $current-value;
  @return adjust_color($color, $alpha: $value);
}

/// Mix two colors taking into account their alpha channels
///
/// @param background - Background color
/// @param overlay - Overlay color to be mixed with the background color
/// @returns Resulting color
///
/// @example
///
///   $background: #fff;
///   $overlay: rgba(0, 255, 0, 0.1);
///   $mixed: mixColorOverlay($background, $overlay); // #e5ffe5
///
@function mixColorOverlay($background, $overlay) {
  $percent: alpha($overlay) * 100%;
  $opaque: opacify($overlay, 1);
  @return mix($opaque, $background, $percent);
};

/// Add modifier to color name string (internal)
///
/// @param {String} $color-name - Color name string to add modifier into
/// @param {String} $modifier - Modifier to be added into color name string
/// @returns {String}
///
/// @example
///
///   __color-add-modifier(primary, contrast);
///
/// @output
///
///   primary:contrast
///
@function __color-add-modifier($modifier, $color-name) {
  @return $color-name + __color-get-modifier-key($modifier);
}

/// Remove modifier from color name string (internal)
///
/// @param {String} $color-name - Color name string to use
/// @param {String} $modifier - Modifier to be removed from color name string
/// @returns {String}
///
/// @example
///
///   $color: __color-add-modifier(primary, contrast); // primary:contrast
///   __color-remove-modifier($color, contrast)        // primary
///
@function __color-remove-modifier($color-name, $modifier) {
  @return str-replace($color-name, __color-get-modifier-key($modifier), "");
}

/// Check if color name string contains certain modifier (internal)
///
/// @param {String} $color-name - Color name string to check
/// @param {String} $modifier - Modifier to search for in color name string
/// @returns {Boolean}
///
/// @example
///
///   $color: __color-add-modifier(primary, contrast);
///   __color-has-modifier($color, contrast) // true
///
@function __color-has-modifier($color-name, $modifier) {
  $modifier-key: __color-get-modifier-key($modifier);
  @return str-index(inspect($color-name), $modifier-key) != null;
}

/// Get modifier key string (internal)
///
/// @param {String} $modifier - String to create modifier key from
/// @returns {String} Modifier key
///
/// @example
///
///   __color-get-modifier-key(my-modifier)
///
/// @output
///
///   :my-modifier
///
@function __color-get-modifier-key($modifier) {
  @return unquote(":" + $modifier);
}
