@import "components/ui/theme/index";

.ui-pagination {
  text-align: center;

  &--gutter-top {
    margin-top: $spacing-3;
  }

  &__backward,
  &__forward {
    &.MuiButton-root .MuiSvgIcon-root {
      width: 21px;
    }

    .MuiSvgIcon-root + .MuiSvgIcon-root {
      margin-left: -14px;
    }
  }
}
