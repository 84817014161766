@import "components/ui/theme/index";

$ui-upload-item-fail-fg: error !default;
$ui-upload-item-fail-icon: error (set-alpha, 0.1) !default;

$ui-upload-item-success-fg: success !default;
$ui-upload-item-success-icon: success (set-alpha, 0.1) !default;

$ui-upload-item-progress-fg: primary !default;

.ui-upload-item {
  margin: 0 (-$spacing-1);
  padding: 0 $spacing-1;

  &__content {
    display: flex;
    height: 24px;
  }

  &:not(:last-child) {
    margin-bottom: $spacing-1;
    white-space: nowrap;
  }

  &__name {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: padding 350ms;
  }

  &--fail,
  &--fail .MuiIconButton-root {
    @include theme-color(color, $ui-upload-item-fail-fg);
  }

  &--fail .MuiIconButton-root:hover {
    @include theme-color(background, $ui-upload-item-fail-icon);
  }

  &--success,
  &--success .MuiIconButton-root {
    @include theme-color(color, $ui-upload-item-success-fg);
  }

  &--success .MuiIconButton-root:hover {
    @include theme-color(background, $ui-upload-item-success-icon);
  }

  &__progress {
    margin: 0 8px;
    @include theme-color(color, $ui-upload-item-progress-fg);
  }
}