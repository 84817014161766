@import "components/ui/theme/index";

.ui-home-invoices {
  display: flex;

  @media (max-width: $sm-max) {
    flex-direction: column;
  }
  
  .ui-invoice-summary {
    @media (min-width: $md-min) {
      flex: 1;
      margin-right: $spacing-3;
    }
  }

  &__button {
    display: flex;
    align-items: center;

    @media (min-width: $md-min) {
      margin-left: auto;
    }
    
    @media (max-width: $sm-max) {
      flex-direction: column;
      margin-top: $spacing-4;
    }
  }
}