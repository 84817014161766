@import "components/ui/theme/index";

$ui-portal-feature-card-title-fg: primary !default;
$ui-portal-feature-card-icon: primary !default;

.ui-portal-feature-card {
  text-align: center;

  @media (max-width: $lg-max) {
    padding: $spacing-2 0;
  }
  
  svg {
    margin-bottom: $spacing-2;
    height: 83px;
    @include theme-color(color, $ui-portal-feature-card-icon);
    
    @media (max-width: $xs-max) {
      height: 56px;
    }
  }

  &__title {
    margin-bottom: $spacing-2 !important;
    @include theme-color(color, $ui-portal-feature-card-title-fg);

    @media (max-width: $xs-max) {
      margin-bottom: $spacing-3 !important;
    }
  }

  &__content {
    margin-bottom: $spacing-2 !important;
  }
}