body {
  margin: 0;
  line-height: 1;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
canvas,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  background: transparent;
}

:focus {
  outline: 0;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: none;
}

a {
  text-decoration: none;
  line-height: normal;
}

a img {
  border: 0;
}

img {
  vertical-align: top;
}

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption,
th,
td {
  text-align: left;
  font-weight: normal;
}

hr {
  display: block;
  height: 1px;
  border: none;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input,
select {
  vertical-align: middle;
}

input,
select,
textarea,
button {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

sup,
sub {
  font-size: xx-small;
  height: 0;
  line-height: 1;
}

sup {
  vertical-align: baseline;
  position: relative;
  bottom: 1ex;
}

sub {
  vertical-align: text-bottom;
}

input::-ms-clear {
  display: none;
}

input,
textarea {
  appearance: none;
}

button,
[type="button"],
[type="submit"] {
  -webkit-border-fit: border !important;
}

button,
input {
  &::moz-focus-inner {
    padding: 0;
    margin: 0;
  }
}

input,
textarea,
select,
button {
  margin: 0;
}

// Get rid of Chrome autofill styles
input,
textarea,
select {
  &:-webkit-autofill,
  &:-webkit-autofill:hover, 
  &:-webkit-autofill:focus,
  &:-internal-autofill-selected {
    transition-delay: 99999s;
  }
}