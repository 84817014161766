@import "components/ui/theme/index";

$ui-chart-background: text (set-alpha, 0.03) !default;

.ui-chart {
  overflow-x: auto;
  @include theme-color(background, $ui-chart-background);

  &__content {
    position: relative;
    padding: $spacing-5 $spacing-4 $spacing-2 $spacing-3;
  }

  &--title &__content {
    padding-top: $spacing-2;
  }
}
