@import "components/ui/theme/index";

.ui-header-login-form {
  margin-top: $spacing-2;
  
  .MuiTextField-root {
    width: 220px;
  }

  &__forgotten-password {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: $spacing-1 * 0.5;
    height: 42px;
  }

  &__submit {
    height: 40px;
  }
}
