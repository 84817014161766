@import "components/ui/theme/index";

$ui-demand-status-new: primary !default;
$ui-demand-status-in-progress: info !default;
$ui-demand-status-escalated: info !default;
$ui-demand-status-closed: success !default;
$ui-demand-status-unknown: warning !default;

.ui-demand-status {
  display: flex;

  .MuiSvgIcon-root {
    margin-right: $spacing-1 * 1.25;
    width: 24px;
    height: 24px;
  }

  &--new {
    @include theme-color(color, $ui-demand-status-new);
  }
  
  &--in-progress {
    @include theme-color(color, $ui-demand-status-in-progress);
  }
  
  &--escalated {
    @include theme-color(color, $ui-demand-status-escalated);
  }
  
  &--closed {
    @include theme-color(color, $ui-demand-status-closed);
  }

  &--unknown {
    @include theme-color(color, $ui-demand-status-unknown);
  }

  @at-root .ui-table & {
    margin: -1px 0;
  }
}