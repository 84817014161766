@import "components/ui/theme/index";

.MuiIconButton-root.ui-mobile-menu {
  @media (min-width: $xl-min) {
    display: none;
  }

  .MuiSvgIcon-root {
    width: 44px;
    height: 44px;
  }
}