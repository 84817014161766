@import "components/ui/theme/index";

.cookies-popup-dialog {
    .MuiDialogTitle-root  {
        padding: 0 !important;
    }
    p {
        margin: 20px 0px !important;
        font-size: 17px;
    }

    .ui-tabs {
        border-radius: 0 15px 0 0 !important;
    }

    .MuiDivider-root {
        margin: 10px 0px;
    }

    &-approve_all_button {
        padding: 15px 50px !important;
    }

    .ui-button-actions {
        justify-content: flex-end !important; 

        button:first-child {
            padding: 15px 50px !important;
        }
    }

    &-cookies-type-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .MuiSvgIcon-root {
            color: #4caf50;
            height: 40px;
            width: 40px;
        }
        &_right-side {
            margin-right: 30px;

            .MuiSwitch-track {
                @include theme-color(background, primary-light);
            }
        }
    }
}