@import "components/ui/theme/index";

$ui-place-list-no-places-fg: disabled !default;

.ui-place-list {
  &__no-places {
    @include theme-color(color, $ui-place-list-no-places-fg);
  }

  &__item + &__item {
    margin-top: $spacing-1;
  }

  &__consumption {
    margin-right: $spacing-1;

    .MuiSvgIcon-root {
      vertical-align: bottom;
      margin-right: $spacing-1 * 0.5;
    }
  }
}