@import "components/ui/theme/index";

$ui-grid-kit-cell-border: text (set-alpha, 0.25) !default;
$ui-grid-kit-cell-bg: text (set-alpha, 0.03) !default;

.ui-grid-kit {
  .MuiPaper-root {
    padding: $spacing-3;
    border: 1px solid;
    text-align: center;
    @include theme-color(border-color, $ui-grid-kit-cell-border);
    @include theme-color(background, $ui-grid-kit-cell-bg);
  }
}
