@import "components/ui/theme/index";

$ui-table-row-border: text (set-alpha, 0.105) !default;
$ui-table-row-odd-bg: text (set-alpha, 0.0275) !default;
$ui-table-row-hover-bg: text (set-alpha, 0.055) !default;

.MuiTableRow-root.ui-table-row {
  .MuiTableCell-root {
    @include theme-color(border-color, $ui-table-row-border);

    &:first-child {
      border-left: 1px solid;
    }

    &:last-child {
      border-right: 1px solid;
    }
  }

  &:not(:first-child) .MuiTableCell-root {
    border-top: 1px solid;
  }

  &:last-child .MuiTableCell-root {
    border-bottom: 1px solid;
  }

  &:nth-child(odd) {
    @include theme-color(background, $ui-table-row-odd-bg);
  }

  @at-root .ui-table--hover &:hover {
    @include theme-color(background, $ui-table-row-hover-bg);
  }
}