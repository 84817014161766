@import "components/ui/theme/index";

$ui-icon-button-primary-fg: primary !default;
$ui-icon-button-primary-bg: $ui-icon-button-primary-fg (set-alpha, 0.1) !default;

$ui-icon-button-secondary-fg: secondary !default;
$ui-icon-button-secondary-bg: $ui-icon-button-secondary-fg (set-alpha, 0.1) !default;

$ui-icon-button-success-fg: success !default;
$ui-icon-button-success-bg: $ui-icon-button-success-fg (set-alpha, 0.1) !default;

$ui-icon-button-warning-fg: warning !default;
$ui-icon-button-warning-bg: $ui-icon-button-warning-fg (set-alpha, 0.1) !default;

$ui-icon-button-error-fg: error !default;
$ui-icon-button-error-bg: $ui-icon-button-error-fg (set-alpha, 0.1) !default;

.MuiIconButton-root.ui-icon-button {
  transition-property: color, background;
  transition-duration: $speed-slow;

  .MuiIconButton-label > span {
    display: flex;

    .MuiSvgIcon-root {
      vertical-align: top;
    }
  }

  &--pending {
    pointer-events: none;
  }

  &--size-small {
    padding: $spacing-1 * 0.75;
  }

  &--color-primary {
    @include theme-color(color, $ui-icon-button-primary-fg);

    &:hover {
      @include theme-color(background, $ui-icon-button-primary-bg);
    }
  }

  &--color-secondary {
    @include theme-color(color, $ui-icon-button-secondary-fg);

    &:hover {
      @include theme-color(background, $ui-icon-button-secondary-bg);
    }
  }

  &--color-success {
    @include theme-color(color, $ui-icon-button-success-fg);

    &:hover {
      @include theme-color(background, $ui-icon-button-success-bg);
    }
  }

  &--color-warning {
    @include theme-color(color, $ui-icon-button-warning-fg);

    &:hover {
      @include theme-color(background, $ui-icon-button-warning-bg);
    }
  }

  &--color-error {
    @include theme-color(color, $ui-icon-button-error-fg);

    &:hover {
      @include theme-color(background, $ui-icon-button-error-bg);
    }
  }

  &.Mui-disabled {
    @include disabled;
  }
}
