@import "components/ui/theme/index";
@import "components/ui/divider/divider";
@import "components/ui/link/link";
@import "components/ui/typography/typography";

$ui-cms-content-bullet: primary !default;

.ui-cms-content {
  font-size: $body-font-size;
  line-height: $body-line-height;

  h2 {
    margin-bottom: $spacing-2 0 $spacing-3;
    font-size: $h2-font-size;
    font-weight: $h2-font-weight;
    line-height: $h2-line-height;
  }

  h3 {
    margin: $spacing-2 0;
    font-size: $h3-font-size;
    font-weight: $h3-font-weight;
    line-height: $h3-line-height;
  }

  p {
    margin: $spacing-2 0;
    font-size: $body-font-size;
  }

  small {
    font-size: $small-font-size;
  }

  hr {
    @extend .ui-divider;
  }

  a {
    @extend .ui-link;
    @extend .ui-link--hover-animation;
    @extend .ui-link--underline;
    text-decoration: none !important;
  }

  b,
  strong {
    font-weight: bold;
  }

  i,
  em {
    font-style: italic;
  }

  u {
    text-decoration: underline;
  }

  img {
    display: block;
    clear: both;
    margin: $spacing-2 auto;
    max-width: 100%;
    max-height: 480px;
  }

  iframe {
    display: block;
    clear: both;
    margin: $spacing-2 auto;
    max-width: 100%;
  }

  h2,
  h3,
  p,
  img,
  iframe {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  ul {
    li {
      position: relative;
      padding-left: $spacing-1 * 2.5;

      &:not(:last-child) {
        margin-bottom: $spacing-1;
      }

      &::before {
        content: '';
        position: absolute;
        top: 8px;
        left: 0;
        border-radius: 8px;
        width: 8px;
        height: 8px;
        @include theme-color(background, $ui-cms-content-bullet);
      }

      > li:first-child {
        margin-top: $spacing-1;
      }
    }
  }
}