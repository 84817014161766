@import "components/ui/theme/index";

$ui-dialog-content-fg: text !default;

.ui-dialog-content.MuiDialogContent-root {
  padding: $spacing-4 $spacing-3;
  @include theme-color(color, $ui-dialog-content-fg);

  .MuiDivider-root {
    margin: $spacing-4 0;
  }
}