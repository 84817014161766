@import "components/ui/theme/index";

.ui-app-header {
  @media (max-width: $xs-max) {
    padding: $spacing-3 0 $spacing-2;
  }

  &__content {
    display: flex;
    align-items: center;
    
    @media (min-width: $sm-min) {
      margin-bottom: $spacing-1 * 0.5;
    }

    @media (max-width: $xs-max) {
      flex-direction: column;
    }
  }

  &__logo {
    @media (min-width: $sm-min) {
      margin-right: $spacing-2;
    }
  }

  &__right-panel {
    display: flex;
    align-items: center;
    margin: $spacing-3 0 $spacing-1 * 2.25;
    
    @media (max-width: $xs-max) {
      margin: $spacing-1 * 1.75 0 $spacing-1;
    }

    @media (min-width: $sm-min) {
      margin-left: auto !important;
    }

    > .MuiIconButton-root {
      width: 64px;
      height: 64px;
    }
  }

  &--unlogged &__right-panel {
    @media (min-width: $xl-min) {
      margin: 0;
    }
  }

  .ui-header-login-form {
    @media (max-width: $lg-max) {
      display: none;
    }
  }

  .ui-header-login-dialog {
    @media (min-width: $xl-min) {
      display: none;
    }
  }

  &--unlogged .ui-theme-switch {
    @media (min-width: $xl-min) {
      margin: -$spacing-3 0 0 $spacing-3;
    }
  }
}