@import "components/ui/theme/index";

.MuiFormGroup-root.ui-radio-group {
  &--direction-column {
    flex-direction: column;
  }

  &--direction-row {
    flex-direction: row;
  }
}