@import "components/ui/theme/index";

$ui-pickers-calendar-border: primary !default;
$ui-pickers-calendar-fg: primary !default;

$ui-pickers-calendar-selected-border: primary !default;
$ui-pickers-calendar-selected-bg: primary !default;
$ui-pickers-calendar-selected-fg: get-contrast-color($ui-pickers-calendar-selected-bg) !default;

$ui-pickers-calendar-hover-fg: primary !default;
$ui-pickers-calendar-hover-bg: $ui-pickers-calendar-hover-fg (set-alpha, 0.1) !default;
$ui-pickers-calendar-hover-border: primary !default;

$ui-pickers-calendar-toolbar-bg: primary !default;
$ui-pickers-calendar-toolbar-fg: get-contrast-color($ui-pickers-calendar-toolbar-bg) !default;

$ui-pickers-clock-bg: primary (set-alpha, 0.1) !default;
$ui-pickers-clock-pointer-thumb: get-contrast-color($ui-pickers-calendar-selected-bg) !default;

.MuiPaper-root.MuiPickersModalDialog-dialogRoot {
  border: 1px solid;
  box-shadow:
    0 3px 3px -2px rgba(#000, 0.2),
    0 3px 4px 0 rgba(#000, 0.14),
    0 1px 8px 0 rgba(#000, 0.12);
  @include theme-color(border-color, $ui-pickers-calendar-border);

  @at-root .MuiPickersModalDialog-dialogContainer,
  & {
    .MuiTypography-root,
    .MuiPickersCalendar-weekDayLabel,
    .MuiPickersDay-root,
    .MuiPickersArrowSwitcher-iconButton,
    .MuiPickersCalendarHeader-yearSelectionSwitcher,
    .MuiPickersYear-yearButton {
      @include theme-color(color, $ui-pickers-calendar-fg);
    }

    button {
      &:hover,
      &:focus {
        @include theme-color(color, $ui-pickers-calendar-hover-fg);
        @include theme-color(background, $ui-pickers-calendar-hover-bg);
      }
    }
    
    .MuiPickersDateRangeDay-dayOutsideRangeInterval:hover {
      @include theme-color(border-color, $ui-pickers-calendar-hover-border);
    }

    button.Mui-selected,
    .MuiPickersClockNumber-clockNumberSelected {
      @include theme-color(background, $ui-pickers-calendar-selected-bg);

      &,
      .MuiTypography-root {
        @include theme-color(color, $ui-pickers-calendar-selected-fg);
      }
    }

    .MuiPickersDay-today:not(.Mui-selected) {
      @include theme-color(border-color, $ui-pickers-calendar-selected-border);
    }

    .MuiPickersToolbar-root,
    .MuiDateTimePickerTabs-tabs {
      @include theme-color(background, $ui-pickers-calendar-toolbar-bg);

      &,
      .MuiTypography-root,
      .MuiTab-root {
        @include theme-color(color, $ui-pickers-calendar-toolbar-fg);
      }
    }

    .MuiTab-root {
      transition: opacity $speed-slow;

      &:hover {
        opacity: 1;
      }
    }

    .MuiPickersToolbar-dateTitleContainer .MuiIconButton-root {
      display: none !important;
    }

    .MuiPickersClock-clock {
      @include theme-color(background, $ui-pickers-clock-bg);
    }

    .MuiPickersClockPointer-thumb {
      @include theme-color(background, $ui-pickers-clock-pointer-thumb);
    }

    .Mui-disabled {
      @include disabled;
    }
  }
}
