@import "components/ui/theme/index";

$ui-alert-kit-info-button-bg: info !default;
$ui-alert-kit-info-button-hover-bg: $ui-alert-kit-info-button-bg, (darken 10%) !default;
$ui-alert-kit-info-button-fg: get-contrast-color($ui-alert-kit-info-button-bg) !default;

body .ui-alert-kit {
  .MuiAlert-root {
    margin-bottom: $spacing-1;
  }

  &__buttons {
    margin-top: $spacing-2;
  }

  &__info-button.MuiButton-root {
    @include theme-color(color, $ui-alert-kit-info-button-fg);
    @include theme-color(background, $ui-alert-kit-info-button-bg);

    &:hover {
      @include theme-color(background, $ui-alert-kit-info-button-hover-bg);
    }
  }
}
