@import "components/ui/theme/index";

$ui-dialog-close: get-contrast-color(primary) !default;

.MuiDialog-root.ui-dialog {
  transform: translateZ(1px); // Safari z-index bug workaround

  &--dragging .MuiDialog-container {
    opacity: 0.125 !important;
  }

  .MuiDialog-paper {
    margin: $spacing-3;
    border-radius: 0 16px;
    width: calc(100% - #{$spacing-3 * 2});
    max-width: 600px;
    min-height: 171px;

    @media (max-width: $xs-max) {
      margin: 0;
      border-radius: 0;
      width: 100%;
    }
  }

  &--width-narrow .MuiDialog-paper {
    max-width: 480px;
  }

 &--width-wide .MuiDialog-paper {
    max-width: 1000px;
  }
  //--- Header

  &--draggable .MuiDialogTitle-root {
    cursor: move;
    user-select: none;
  }

  .ui-dialog__close {
    position: absolute;
    top: 8px;
    right: 11px;
    @include theme-color(color, $ui-dialog-close);

    .MuiSvgIcon-root {
      width: 32px;
      height: 32px;
    }
  }
}
