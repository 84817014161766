@import "components/ui/theme/index";

.ui-autocomplete {
  &__field {
    padding: 0 88px 0 0 !important;
  }

  &__input {
    padding: 17px 0 16px 16px !important;
  }
}
