@import "components/ui/theme/index";

$ui-toggle-button-border: primary !default;
$ui-toggle-button-bg: paper !default;
$ui-toggle-button-fg: primary !default;

$ui-toggle-button-selected-border: primary !default;
$ui-toggle-button-selected-bg: primary (set-alpha 0.12) !default;
$ui-toggle-button-selected-fg: primary !default;

.MuiToggleButton-root.ui-toggle-button {
  @include theme-color(border-color, $ui-toggle-button-border);
  @include theme-color(color, $ui-toggle-button-fg);
  @include theme-color(background, $ui-toggle-button-bg);

  &.Mui-selected {
    @include theme-color(border-color, $ui-toggle-button-selected-border);
    @include theme-color(color, $ui-toggle-button-selected-fg);
    @include theme-color(background, $ui-toggle-button-selected-bg);
  }

  &.Mui-disabled .MuiToggleButton-label {
    opacity: 0.35;
  }
}
