@import "components/ui/theme/index";

$ui-accordion-divider: paper (set-alpha, 0.5);

.MuiAccordion-root.ui-accordion {
  border-radius: 4px;

  &::before {
    left: 3px;
    right: 3px;
    opacity: 0;
    @include theme-color(background, $ui-accordion-divider);
  }

  + .MuiAccordion-root::before {
    opacity: 1 !important;
  }

  &,
  &.Mui-expanded {
    margin: 0;
  }
}