/// Replace substring in string
///
/// @param {String} $string - String to search in
/// @param {String} $search - Substring to be replaced
/// @param {String} $replace - New value
/// @return {String} Updated string
///
@function str-replace($string, $search, $replace) {
  $index: str-index($string, $search);
  @if $index {
    @return
      str-slice($string, 1, $index - 1) +
      $replace +
      str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }
  @return $string;
}
