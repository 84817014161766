@import "components/ui/theme/index";

.ui-slider-kit {
  &__row {
    display: flex;
    justify-content: center;

    &:not(:last-child) {
      margin-bottom: $spacing-3;
    }
  }

  .MuiSlider-root {
    margin-left: auto;
    margin-right: auto;
    max-width: 640px;
  }
}
