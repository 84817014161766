@import "components/ui/theme/index";

.MuiTreeItem-root.ui-tree-item {
  margin: $spacing-1 0;
  user-select: none;

  .MuiTreeItem-content {
    background: none;

    &.Mui-focused {
      outline: 1px dotted;
    }

    &:hover {
      outline: 1px dashed;
    }
  }
}
