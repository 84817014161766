@import "components/ui/theme/index";

$ui-text-field-border: primary !default;
$ui-text-field-bg: primary (set-alpha, 0.028) !default;
$ui-text-field-fg: primary !default;
$ui-text-field-placeholder: primary (set-alpha, 0.55) !default;

$ui-text-field-focus-border: $ui-text-field-border !default;
$ui-text-field-focus-bg: transparent !default;
$ui-text-field-focus-fg: $ui-text-field-fg !default;
$ui-text-field-focus-placeholder: $ui-text-field-placeholder !default;

.ui-text-field {
  .MuiOutlinedInput-root {
    border-radius: 0;

    &.MuiInputBase-multiline {
      padding: 0;
    }
  }
  
  .MuiInputBase-input.Mui-disabled {
    -webkit-text-fill-color: inherit;
  }

  .MuiOutlinedInput-notchedOutline,
  .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-width: 1px;
  }

  .MuiOutlinedInput-notchedOutline {
    transition: border-color $speed-slow;
  }

  .MuiOutlinedInput-input {
    padding: $spacing-2 + 1 $spacing-2 $spacing-2;
  }

  .MuiOutlinedInput-multiline {
    padding: 0;
  }

  &--small .MuiOutlinedInput-input {
    padding: $spacing-1 * 1.25 + 1 $spacing-1 * 1.5 $spacing-1 * 1.25;
  }

  .MuiInputBase-root {
    transition: background $speed-slow;
  }

  .MuiInputBase-input {
    color: inherit;
    transition: color $speed-slow;

    &::placeholder {
      opacity: 1;
    }
  }

  &--disabled {
    @include disabled;
  }

  &--hidden {
    display: none;
  }

  //--- Reset default appearance

  .MuiInputBase-input {
    &::-ms-clear,
    &::-ms-reveal {
      display: none;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      margin: 0;
      -webkit-appearance: none;
    }

    &[type="number"] {
      -moz-appearance: textfield;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-transition-property: color, background-color;
      -webkit-transition-delay: 99999s;
    }

    &[readonly] {
      cursor: default;
    }
  }

  //--- Adornments

  .MuiInputAdornment-root {
    padding-top: 1px;
  }

  .MuiInputAdornment-root,
  .MuiTypography-root {
    color: inherit;
  }

  .MuiOutlinedInput-adornedStart .MuiInputBase-input {
    padding-left: $spacing-1;
  }

  .MuiOutlinedInput-adornedEnd .MuiInputBase-input {
    padding-right: $spacing-1;
  }

  .MuiInputAdornment-positionStart {
    margin-right: 0;
  }

  .MuiInputAdornment-positionEnd {
    margin-left: 0;
  }

  &--password .MuiOutlinedInput-adornedEnd {
    padding-right: $spacing-1 * 1.25;
  }

  &--small#{&}--password .MuiOutlinedInput-adornedEnd {
    padding-right: $spacing-1;
  }

  .MuiSvgIcon-root {
    width: 24px;
    height: 24px;
  }

  .MuiLinearProgress-root {
    margin-top: -2px;
    height: 2px;
  }

  //--- Alignment

  &--align-left .MuiInputBase-input {
    text-align: left;
  }

  &--align-center .MuiInputBase-input {
    text-align: center;
  }

  &--align-right .MuiInputBase-input {
    text-align: right;
  }

  //--- Default colors

  .MuiInputBase-root {
    @include theme-color(color, $ui-text-field-fg);
    @include theme-color(background, $ui-text-field-bg);

    .MuiInputBase-input::placeholder {
      @include theme-color(color, $ui-text-field-placeholder);
    }

    .MuiOutlinedInput-notchedOutline {
      @include theme-color(border-color, $ui-text-field-border);
    }
  }

  //--- Focus colors

  .MuiInputBase-root.Mui-focused {
    @include theme-color(color, $ui-text-field-focus-fg);
    @include theme-color(background, $ui-text-field-focus-bg);

    .MuiInputBase-input::placeholder {
      @include theme-color(color, $ui-text-field-focus-placeholder);
    }

    .MuiOutlinedInput-notchedOutline {
      @include theme-color(border-color, $ui-text-field-focus-border);
    }
  }
}
