@import "components/ui/theme/index";

$ui-data-table-vertical-header-bg: text (set-alpha, 0.02) !default;
$ui-data-table-vertical-row-divider: text (set-alpha, 0.2) !default;
$ui-data-table-vertical-row-odd-bg: text (set-alpha, 0.0275) !default;
$ui-data-table-vertical-row-even-bg: paper !default;

.MuiTableContainer-root.ui-data-table {
  position: relative;
  
  .ui-data-table__header {
    &--nowrap {
      @media (min-width: $sm-min) {
        white-space: nowrap;
      }
    }
  }

  .ui-data-table__cell {
    &--bold {
      font-weight: bold;
    }

    &--nowrap {
      @media (min-width: $sm-min) {
        white-space: nowrap;
      }
    }

    &--shrink {
      @media (min-width: $sm-min) {
        width: 1px;
      }
    }
  }

  .ui-data-table__header,
  .ui-data-table__cell {
    > div {
      position: relative;
    }
  }

  &--vertical {
    .MuiTableCell-root {
      padding: $spacing-2 $spacing-3;
    }

    .MuiTableRow-root:first-child .MuiTableCell-root {
      border-top: 1px solid;
    }
    
    th.MuiTableCell-root {
      border-right: 1px solid;
      @include theme-color(border-right-color, $ui-data-table-vertical-row-divider);
    }

    .MuiTableRow-root:first-child .MuiTableCell-root {
      @include theme-color(border-top-color, $ui-data-table-vertical-row-divider);
    }

    .MuiTableRow-root:last-child .MuiTableCell-root {
      @include theme-color(border-bottom-color, $ui-data-table-vertical-row-divider);
    }

    .MuiTableRow-root.ui-data-table__row {
      &--odd {
        @include theme-color(background, $ui-data-table-vertical-row-odd-bg);
      }

      &--even {
        @include theme-color(background, $ui-data-table-vertical-row-even-bg);
      }
    }

    .ui-data-table__row--odd + .ui-data-table__row--even,
    .ui-data-table__row--even + .ui-data-table__row--odd {
      .MuiTableCell-root {
        border-top: 1px solid;
        @include theme-color(border-top-color, $ui-data-table-vertical-row-divider);
      }
    }
  }

  &--horizontal.ui-data-table--menu .ui-data-table__header:last-child,
  &--vertical.ui-data-table--menu .MuiTableRow-root:first-child .ui-data-table__cell:last-child {
    padding-right: 48px;
  }
}