@import "components/ui/theme/index";

$ui-tooltip-box-border: text (set-alpha, 0.25) !default;
$ui-tooltip-box-hover: primary !default;

.ui-tooltip-box {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  width: 96px;
  height: 96px;
  user-select: none;
  cursor: default;
  transition: color $speed-fast;
  @include theme-color(border-color, $ui-tooltip-box-border);

  .MuiSvgIcon-root {
    width: 32px;
    height: 32px;
    transition: fill $speed-fast;
  }

  &:hover {
    @include theme-color(color, $ui-tooltip-box-hover);

    .MuiSvgIcon-root {
      @include theme-color(fill, $ui-tooltip-box-hover);
    }
  }
}
