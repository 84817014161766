@import "components/ui/theme/index";

$ui-breadcrumbs-link-bg: primary !default;
$ui-breadcrumbs-link-fg: get-contrast-color($ui-breadcrumbs-link-bg) !default;

$ui-breadcrumbs-link-hover-bg: primary-dark !default;
$ui-breadcrumbs-link-hover-fg: $ui-breadcrumbs-link-fg;

$ui-breadcrumbs-link-active-bg: primary (set-saturation, 0%) !default;
$ui-breadcrumbs-link-active-fg: $ui-breadcrumbs-link-fg !default;

$ui-breadcrumbs-divider: text !default;

.ui-breadcrumbs {
  li {
    margin-top: $spacing-1;
    margin-bottom: $spacing-1;

    > a {
      display: inline-block;
      border-radius: 4px;
      padding: $spacing-1 * 0.5 $spacing-1;
      font-size: $small-font-size;
      transform: translateZ(1px);
      transition-property: color, background, transform;
      transition-duration: $speed-slow;

      @media (max-width: $xs-max) {
        margin-right: $spacing-2;
      }
      
      &,
      &:focus {
        @include theme-color(color, $ui-breadcrumbs-link-fg);
        @include theme-color(background, $ui-breadcrumbs-link-bg);
      }

      &:hover {
        transform: scale(1.1) translateZ(1px);
        @include theme-color(color, $ui-breadcrumbs-link-hover-fg);
        @include theme-color(background, $ui-breadcrumbs-link-hover-bg);
      }
    }

    &:last-child > a {
      pointer-events: none;
      @include theme-color(color, $ui-breadcrumbs-link-active-fg);
      @include theme-color(background, $ui-breadcrumbs-link-active-bg);
    }
  }

  .MuiBreadcrumbs-separator {
    margin-left: $spacing-1 * 1.5;
    margin-right: $spacing-1 * 1.5;
    @include theme-color(color, $ui-breadcrumbs-divider);

    @media (max-width: $xs-max) {
      display: none;
    }
  }
}