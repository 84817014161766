@import "components/ui/theme/index";

.ui-upload-item-icon {
  display: inline-block;
  vertical-align: top;
  margin: -6px 0;

  &--disabled .MuiIconButton-root {
    background: none !important;
    cursor: default !important;
  }

  .MuiSvgIcon-root {
    width: 24px;
    height: 24px;
  }
}