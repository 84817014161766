@import "components/ui/theme/index";

.MuiListItemIcon-root.ui-list-item-icon {
  margin-right: $spacing-2;
  min-width: 24px;
  color: inherit;

  .MuiSvgIcon-root {
    width: 24px;
    height: 24px;
  }
}