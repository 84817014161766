@import "components/ui/theme/index";

$ui-button-actions-margin: $spacing-1 * 1.25;

.ui-button-actions {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
  margin: 0 $ui-button-actions-margin * -0.5 $ui-button-actions-margin * -1;

  &--align-center {
    justify-content: center;
  }

  &--align-right {
    justify-content: flex-end;
  }

  &--rows {
    flex-direction: column;
    align-items: flex-start;
  }

  > .MuiButton-root,
  > .ui-link {
    margin: 0 $ui-button-actions-margin * 0.5 $ui-button-actions-margin;
  }

  &--narrow {
    flex-direction: column;

    > .MuiButton-root {
      width: calc(100% - #{$ui-button-actions-margin});
      max-width: initial !important;
    }

    > .ui-link {
      margin-right: auto !important;
    }

    > .MuiIconButton-root {
      margin: 0 auto $ui-button-actions-margin;
    }
  }
}
