@import "components/ui/theme/index";

$ui-not-found-view-heading: primary !default;
$ui-not-found-view-image: primary !default;

.ui-not-found-view {
  &.MuiContainer-root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 18px;
  }

  &__image {
    display: block;
    margin: -$spacing-2 auto 0;
    width: 240px;
    height: 240px;
    @include theme-color(fill, $ui-not-found-view-image);

    @media (max-width: $xs-max) {
      margin: 0 auto;
      width: 160px;
      height: 160px;
    }
  }

  &__content {
    margin: 32px 0;
    max-width: $md-max;
    text-align: center;
  }

  &__heading {
    text-transform: uppercase;
    @include theme-color(color, $ui-not-found-view-heading);
  }

  &__homepage {
    margin-top: $spacing-6;
    font-style: italic;
  }
}
