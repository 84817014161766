@import "components/ui/theme/index";

$ui-card-header-fg: primary !default;

.MuiCardHeader-root.ui-card-header {
  @include theme-color(color, $ui-card-header-fg);

  .MuiCardHeader-title {
    display: flex;

    .MuiSvgIcon-root {
      margin-right: $spacing-1;
      width: 32px;
      height: 32px;
    }
  }
}